import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import {
  IProfile,
  IProfileDTO,
  IProfileFull,
  IProfileFullDTO,
  IProfileShort,
  IProfileShortDTO,
  IRequisitionParticipant,
  ProfileViewModelFactory,
  RequisitionParticipantViewModel,
} from '@project/view-models';
import { TGuid } from '@core/helpers';
import { monitorLogger } from '../../monitor/monitor-logger';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiProviderService {
  constructor(private apiService: ApiService) {}

  getMyProfileInformation(): Observable<IProfileFull> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/Profiles/me/full`).pipe(
      map((response: IProfileFullDTO) => {
        monitorLogger.identifyProfile(
          response.id,
          response.email,
          response.cpf_number,
          response.gender,
          response.profile_type,
        );
        return ProfileViewModelFactory.createFull(response);
      }),
    );
  }

  isPatientProfileFilled(): Observable<boolean> {
    return this.apiService.get<boolean>(`${environment.environmentVariables.apiCoreUrl}/Patients/me/filled`);
  }

  getProfile(id: TGuid): Observable<IProfile> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Profiles/${id}`)
      .pipe(map((dto: IProfileDTO) => ProfileViewModelFactory.create(dto)))
      .pipe(
        map((response: IProfile) => {
          monitorLogger.identifyProfile(
            response.id,
            response.email,
            response.cpfNumber,
            response.gender,
            response.profileType,
          );
          return response;
        }),
      );
  }

  getRequisitionParticipantProfile(id: TGuid): Observable<IRequisitionParticipant> {
    return this.getProfile(id).pipe(map((profile) => RequisitionParticipantViewModel.create(profile)));
  }

  getShortProfiles(profileIds: TGuid[]): Observable<Record<TGuid, IProfileShort>> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Profiles/shorts`, { ids: profileIds })
      .pipe(
        map((response: IProfileShortDTO[]) =>
          response.map((profileDto) => ProfileViewModelFactory.createShort(profileDto)),
        ),
        map((profiles: IProfileShort[]) =>
          profiles.reduce((acc, item: IProfileShort) => {
            acc[item.id] = item;
            return acc;
          }, {}),
        ),
      );
  }

  public disableProfile(profileType: string, profileId: string): Observable<void> {
    return this.apiService.put<void>(
      `${environment.environmentVariables.apiAuthUrl}/profile/${profileType}/${profileId}/disable`,
    );
  }

  public enableProfile(profileType: string, profileId: string): Observable<void> {
    return this.apiService.put<void>(
      `${environment.environmentVariables.apiAuthUrl}/profile/${profileType}/${profileId}/enable`,
    );
  }
}
