<lib-modal-header (close)="close()">{{ headerTitle }}</lib-modal-header>
<lib-modal-body>
  <div class="container-rating">
    <lib-form-control>
      <label>
        <span libLabel>{{ "modals.reason" | translate }}</span>
        <lib-select [options]="reasons$ | async" [(ngModel)]="reason.reason_id" (change)="valueSelect()"></lib-select>
      </label>
    </lib-form-control>
  </div>
  <lib-form-control>
    <label>
      <lib-input
        *ngIf="isOther"
        type="input"
        placeholder="{{ 'modals.rating.optional' | translate }}"
        [(ngModel)]="reason.description"
      >
      </lib-input>
    </label>
  </lib-form-control>
</lib-modal-body>
<lib-modal-footer>
  <div class="actions">
    <button libButton (click)="close()" [appearance]="'grey-outline'">
      {{ "modals.confirm.actions.notnow" | translate }}
    </button>
    <button libButton (click)="sendRating()" [isPending]="isLoading">
      {{ "actions.submit" | translate }}
    </button>
  </div>
</lib-modal-footer>
