import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '../../translate/translate.module';
import { ModalDialogLayoutModule } from '@lib/ui';
import { ModalQrCodeComponent } from './modal-qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [ModalQrCodeComponent],
  imports: [CommonModule, TranslateModule, ModalDialogLayoutModule, QRCodeModule],
  exports: [ModalQrCodeComponent],
})
export class ModalQrCodeModule {}
