import { EServiceSocketMessageTypeDTO, ESocketMessageTypeDTO } from '@project/view-models';

export const socketMonitor = (trackEvent: any) => {
  return {
    trackStatus: (name: string, msg?: any) => {
      trackEvent(name, msg);
    },
    trackSend: (type: string, msg?: any) => {
      trackEvent(type, msg);
    },
    trackMessage: (msg: any) => {
      try {
        const data = JSON.parse(msg);

        switch (data.type) {
          case ESocketMessageTypeDTO.PatientsQueueInformation:
            trackEvent('PATIENTS_QUEUE', data);
            break;
          case ESocketMessageTypeDTO.RequisitionInformation:
            trackEvent('REQUISITION_INFORMATION', data);
            break;
          case ESocketMessageTypeDTO.ChatMessage:
            trackEvent('MESSAGE_ADDED', data);
            break;
          case EServiceSocketMessageTypeDTO.CallStarted:
            trackEvent('CALL_STARTED', data);
            break;
          case EServiceSocketMessageTypeDTO.CallDeclined:
            trackEvent('CALL_DECLINED', data);
            break;
          case EServiceSocketMessageTypeDTO.CallEnded:
            trackEvent('CALL_ENDED', data);
            break;
          case ESocketMessageTypeDTO.DisconnectByOtherLogin:
            trackEvent('DISCONNECT_THROUGH_MULTIPLE_DEVICES', data);
            break;
          default:
            trackEvent('UNKNOW', data);
            break;
        }
      } catch (e) {
        console.error(e);
        trackEvent(`ERROR`, e?.message);
      }
    },
    trackError: (data: any) => {
      trackEvent(`ERROR`, JSON.stringify(data));
    },
  };
};
