import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { ChatFormComponent } from './chat-form/chat-form.component';
import { ChatMessagesListComponent } from './chat-messages-list/chat-messages-list.component';
import { AutoSizedTextareaModule } from '@lib/ui';
import { IconsModule } from '@lib/icons';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@project/translate';
import { ChatRoomService } from './chat-room.service';

@NgModule({
  declarations: [ChatMessageComponent, ChatRoomComponent, ChatFormComponent, ChatMessagesListComponent],
  exports: [ChatRoomComponent, ChatFormComponent],
  imports: [CommonModule, IconsModule, ReactiveFormsModule, TranslateModule, AutoSizedTextareaModule],
  providers: [ChatRoomService, ChatRoomComponent],
})
export class ChatRoomModule {}
