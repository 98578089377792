import { Injectable } from '@angular/core';
import { TGuid } from '@core/helpers';
import { GuidService } from '@core/services';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectClientIdService {
  private STORAGE_KEY = 'client-id';

  constructor(private guidService: GuidService, private localStorageService: LocalStorageService) {}

  setClientId(id: TGuid) {
    this.localStorageService.store(this.STORAGE_KEY, id);
  }

  getClientId(): TGuid {
    const clientId: TGuid = this.getStoredClientId();

    if (!clientId || !this.guidService.isValid(clientId)) {
      return this.generateAndStoreClientId();
    }

    return clientId;
  }

  private generateAndStoreClientId(): TGuid {
    const guid = this.guidService.generate();
    this.localStorageService.store(this.STORAGE_KEY, guid);
    return guid;
  }

  private getStoredClientId(): TGuid | null {
    return this.localStorageService.getItem(this.STORAGE_KEY);
  }
}
