import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AuthorisationStateService } from '../authorisation-state.service';
import { RoutesBuilderService } from '@app/config';
import { UserProfileDataService } from '../user-profile-data.service';
import { EProfileTypeDTO } from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class OnboardingRequiredGuard implements CanActivate, CanLoad {
  private fillPersonalDataRedirects: { [profile in EProfileTypeDTO]?: string } = {
    [EProfileTypeDTO.Patient]: RoutesBuilderService.ONBOARDING.fillPatientPersonalData(),
  };

  constructor(
    private authorisationStateService: AuthorisationStateService,
    private router: Router,
    private userProfileDataService: UserProfileDataService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkWithRedirect();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkWithRedirect();
  }

  private checkWithRedirect(): boolean {
    const authorisationData = this.authorisationStateService.getAuthorizationData();
    if (authorisationData?.passwordChangeRequired) {
      this.router.navigateByUrl(RoutesBuilderService.ONBOARDING.setPassword());
      return false;
    }

    const fillDataRedirectUrl = this.fillPersonalDataRedirects[this.userProfileDataService.profile.profileType];
    if (fillDataRedirectUrl && this.userProfileDataService.isProfileFillRequired()) {
      this.router.navigateByUrl(fillDataRedirectUrl);
      return false;
    }

    return true;
  }
}
