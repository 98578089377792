import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env';
import { ApiService } from '@core/http';
import { TranslateService } from '@project/translate';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(private apiService: ApiService) {}

  getAuthCode(): Observable<any> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/embedded/patients/qr-code`);
  }

  public getErrorMessage(statusCode: number): string {
    switch (statusCode) {
      case 400:
        return TranslateService.localize('errors.400.upload.files');
      case 401:
        return TranslateService.localize('errors.401.upload.files');
      case 403:
        return TranslateService.localize('errors.403.upload.files');
      case 404:
        return TranslateService.localize('errors.404.upload.files');
      case 409:
        return TranslateService.localize('errors.409.upload.files');
      case 429:
        return TranslateService.localize('errors.429.upload.files');
      default:
        if (statusCode >= 500 && statusCode < 600) {
          return TranslateService.localize('errors.500.upload.files');
        }
        return TranslateService.localize('error.default.upload-files');
    }
  }
}
