import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProfileFull } from '@project/view-models';
import { EUserPermission, PERMISSIONS_BY_PROFILE_ROLE } from '@project/shared';
import { map } from 'rxjs/operators';
import { AuthorisationStateService } from './authorisation-state.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileDataService {
  private _profile$ = new BehaviorSubject<IProfileFull | null>(null);
  public profile$ = this._profile$.asObservable();

  private _profileId = new BehaviorSubject<string | null>(null);
  public profileId$ = this._profileId.asObservable();

  private _profileFillRequired: boolean | null = null;

  constructor(private authorisationStateService: AuthorisationStateService) {
    this.profile$.subscribe((profile) => {
      if (profile?.id !== this._profileId.value) {
        this._profileId.next(profile?.id || null);
      }
    });
  }

  get profile(): IProfileFull | null {
    return this._profile$.value;
  }

  isProfileFillRequired(): boolean | null {
    return this._profileFillRequired;
  }

  updateProfileDate(userData: Partial<IProfileFull>) {
    this._profile$.next({ ...this._profile$.value, ...userData });
  }

  setProfileData(userData: IProfileFull | null) {
    this._profile$.next(userData);
  }

  isDoctorActive(): Observable<boolean> {
    return this.profile$.pipe(map((profile) => profile?.doctorStatus === 'Active'));
  }

  isDoctor(): Observable<boolean> {
    return this.profile$.pipe(map((profile) => profile?.isDoctor));
  }

  setIsProfileDataFillRequired(fillRequired: boolean) {
    this._profileFillRequired = fillRequired;
  }

  clear() {
    this._profile$.next(null);
    this._profileFillRequired = null;
  }

  hasPermissions(permissions: EUserPermission[]): boolean {
    const userPermissions = this.getUserPermissions();
    return permissions.every((permission) => userPermissions.includes(permission));
  }

  hasPermissions$(permissions: EUserPermission[]): Observable<boolean> {
    return this.profile$.pipe(map(() => this.hasPermissions(permissions)));
  }

  hasAtLeastOnePermission(permissions: EUserPermission[]): boolean {
    const userPermissions = this.getUserPermissions();
    return permissions.some((permission) => userPermissions.includes(permission));
  }

  hasAtLeastOnePermission$(permissions: EUserPermission[]): Observable<boolean> {
    return this.profile$.pipe(map(() => this.hasAtLeastOnePermission(permissions)));
  }

  private getUserPermissions(): EUserPermission[] {
    const profileType = this.authorisationStateService.getAuthorizationData()?.userType;
    return PERMISSIONS_BY_PROFILE_ROLE[profileType] || [];
  }
}
