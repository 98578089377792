import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env';
// import * as amplitude from '@amplitude/analytics-browser';
// import { monitorLogger } from './app/project/monitor/monitor-logger';
import { version } from '../package.json';
import {
  handlerLocalStorageChange,
  //   handlerDOMContentLoaded,
  //   handlerPermissionListener,
} from './app/project/monitor/handlers';
import { WindowDetectionService } from './app/project/data-providers/api-providers/window-detection.service';

// FIXME: LEMBRAR DE FALAR PRO CHARLESTON. O CÓDIGO DA AMPLITUDE TRAVA COM A WINDOW EMBEDDED.
// NA PRÓXIMA VERSÃO PRETENDO SUBIR COM IFRAME (TALVEZ RESOLVA).

// amplitude.init(environment.environmentVariables.amplitudeApiKey, {
//   autocapture: false,
//   appVersion: version,
// });

const windowDetectionService = new WindowDetectionService();

if (!windowDetectionService.isEmbeddedWindow()) {
  console.log('is not embedded window');
  // if (environment.production) {
  //   enableProdMode();
  //   monitorLogger.initializeAnalytics('production');
  // } else {
  //   console.log('Ver com Charleson...');
  //   //monitorLogger.initializeAnalytics('debug');
  // }
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

  // handlerDOMContentLoaded();
  // handlerPermissionListener();
});

if (!windowDetectionService.isEmbeddedWindow()) {
  console.log('is not embedded window');
  handlerLocalStorageChange();
}
