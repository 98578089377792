import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Cid {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class CidService {
  private cidSubject = new BehaviorSubject<Cid | null>(null);

  setCid(cid: Cid) {
    this.cidSubject.next(cid);
  }
  get currentCid() {
    return this.cidSubject.asObservable();
  }
  clearCid() {
    this.cidSubject.next(null);
  }
}
