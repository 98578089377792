import { monitorLogger } from '../monitor-logger';

export function handlerPermissionListener() {
  monitorPermissions(['camera', 'microphone', 'notifications']);
}

function monitorPermissions(permissionNames: PermissionName[]) {
  permissionNames.forEach((permissionName) => {
    navigator.permissions
      .query({ name: permissionName })
      .then((permissionStatus) => {
        monitorLogger.app.track(`[PERMISSION_${permissionName.toUpperCase()}]`, permissionStatus.state);

        permissionStatus.onchange = () => {
          monitorLogger.app.track(`[PERMISSION_${permissionName.toUpperCase()}]`, permissionStatus.state);
        };
      })
      .catch((error) => {
        console.error(`Erro ao monitorar a permissão ${permissionName}:`, error);
      });
  });
}
