import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

interface FeatureConfig {
  disable_left_nav: boolean;
  only_appointment: boolean;
  only_schedule: boolean;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private readonly CONFIG_KEY = 'featureConfig';
  private readonly initialConfig: FeatureConfig = {
    disable_left_nav: false,
    only_appointment: false,
    only_schedule: false,
  };

  private configSubject = new BehaviorSubject<FeatureConfig>(this.loadStoredConfig());

  config$ = this.configSubject.asObservable();

  constructor(private localStorageService: LocalStorageService) {}

  getConfig(): Observable<FeatureConfig> {
    return this.config$;
  }

  setConfig(key: string, value: any): void {
    const updatedConfig = { ...this.configSubject.value, [key]: value };
    this.configSubject.next(updatedConfig);
    this.saveConfig(updatedConfig);
  }

  private loadStoredConfig(): FeatureConfig {
    const storedConfig = this.localStorageService.getItem(this.CONFIG_KEY);
    return storedConfig ? JSON.parse(storedConfig) : { ...this.initialConfig };
  }

  private saveConfig(config: FeatureConfig): void {
    this.localStorageService.store(this.CONFIG_KEY, JSON.stringify(config));
  }
}
