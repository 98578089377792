import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { monitorLogger } from '../../project/monitor/monitor-logger';

export enum EWebsocketStatus {
  Disconnected = 'Disconnected',
  Connected = 'Connected',
  Connecting = 'Connecting',
  Error = 'Error',
}

export class WebsocketProvider {
  private _messages$ = new Subject<string>();
  public messages$ = this._messages$.asObservable();
  private _status$ = new BehaviorSubject<EWebsocketStatus>(null);
  public status$ = this._status$.asObservable();
  private socket: WebSocket | null;

  public isDown$: Observable<boolean> = this.status$.pipe(
    map((status) => [EWebsocketStatus.Disconnected, EWebsocketStatus.Error].includes(status)),
  );

  public isOnline$: Observable<boolean> = this.status$.pipe(map((status) => EWebsocketStatus.Connected === status));

  public connect(url: string) {
    this.disconnect();
    this.socket = new WebSocket(url);
    this._status$.next(EWebsocketStatus.Connecting);
    this.subscribeSocketEvents();
    monitorLogger.socket.trackStatus('CONNECTING', {
      url,
    });
  }

  public disconnect() {
    if (this.socket) {
      this.resetSocketEventsSubscriptions();
      this.socket.close();
      this.socket = null;
      this._status$.next(EWebsocketStatus.Disconnected);
      monitorLogger.socket.trackStatus('DISCONNECTED');
    }
  }

  public destroy() {
    this.disconnect();
    this._status$.next(null);
  }

  public send(message: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (typeof message === 'string') {
      monitorLogger.socket.trackSend('SEND', {
        data: message,
      });
    } else if (message instanceof Blob) {
      monitorLogger.socket.trackSend('BLOB');
    } else if (message instanceof ArrayBuffer) {
      monitorLogger.socket.trackSend('ARRAY_BUFFER');
    } else if (ArrayBuffer.isView(message)) {
      monitorLogger.socket.trackSend('ARRAY_BUFFER_VIEW');
    }

    this.socket?.send(message);
  }

  private subscribeSocketEvents() {
    this.socket.onopen = () => this.handleOpenConnection();
    this.socket.onerror = (err: Event) => this.handleSocketError(err);
    this.socket.onmessage = (messageEvent: MessageEvent) => this.handleReceiveMessage(messageEvent);
    this.socket.onclose = () => this.handleCloseConnection();
  }

  private resetSocketEventsSubscriptions() {
    this.socket.onopen = null;
    this.socket.onerror = null;
    this.socket.onmessage = null;
    this.socket.onclose = null;
  }

  private handleSocketError(err: Event) {
    monitorLogger.socket.trackError(err);
    console.error('Socket ERROR', err); // todo: debug only
    this._status$.next(EWebsocketStatus.Error);
  }

  private handleOpenConnection() {
    this._status$.next(EWebsocketStatus.Connected);
    monitorLogger.socket.trackStatus('CONNECTED');
  }

  private handleReceiveMessage(message: MessageEvent) {
    monitorLogger.socket.trackMessage(message.data);
    this._messages$.next(message.data);
  }

  private handleCloseConnection() {
    this._status$.next(EWebsocketStatus.Disconnected);
  }
}
