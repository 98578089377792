import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { share } from 'rxjs/operators';
import { WindowDetectionService } from '../data-providers/api-providers/window-detection.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements OnDestroy {
  private onSubject = new Subject<{ key: string; value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor(private windowDetectionService: WindowDetectionService) {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  private getPrefixedKey(key: string): string {
    return this.windowDetectionService.isEmbeddedWindow() ? `_embedded_${key}` : key;
  }

  public getStorage() {
    const s = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      s.push({
        key,
        value: localStorage.getItem(key),
      });
    }
    return s;
  }

  public getItem(key: string): any {
    return localStorage.getItem(this.getPrefixedKey(key));
  }

  public store(key: string, data: any): void {
    localStorage.setItem(this.getPrefixedKey(key), data);
    this.onSubject.next({ key: this.getPrefixedKey(key), value: data });
  }

  public clear(key: string): void {
    localStorage.removeItem(this.getPrefixedKey(key));
    this.onSubject.next({ key: this.getPrefixedKey(key), value: null });
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      const v = event.newValue;
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
