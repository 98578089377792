import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { DateFormatter, DEFAULT_DATE_FORMAT, TDateFormattedString, TGuid } from '@core/helpers';
import {
  IPatient,
  PatientViewModelFactory,
  EGenderDTO,
  IPatientDTO,
  IAddress,
  IFullUpdatePatientDTO,
  IDependents,
  EProfileTypeDTO,
} from '@project/view-models';
import { IPagingResponse, PagingApiService } from '@core/http';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PatientsApiProviderService {
  private readonly API_CORE_URL = environment.environmentVariables.apiCoreUrl;

  constructor(
    private apiService: ApiService,
    private pagingApiService: PagingApiService,
    private localStorageService: LocalStorageService,
  ) {}

  getAll(): Observable<IPatient[]> {
    return this.getPaged(1, Infinity).pipe(map((data) => data.items));
  }

  getPaged(page: number, itemsPerPage: number, query?: Record<string, any>): Observable<IPagingResponse<IPatient>> {
    return this.pagingApiService.request<IPatientDTO, IPatient>({
      url: `${environment.environmentVariables.apiCoreUrl}/Patients`,
      additionalQueryParams: query,
      itemsPerPage,
      itemFactory: (item) => PatientViewModelFactory.createFromDTO(item),
      pageNumber: page,
    });
  }

  getById(id: TGuid): Observable<IPatient> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Patients/${id}`)
      .pipe(map((user) => PatientViewModelFactory.createFromDTO(user)));
  }

  getByDependents(fatherPatientId: TGuid): Observable<IDependents[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Patients/dependents/${fatherPatientId}`)
      .pipe(map((user: IDependents[]) => user));
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiAuthUrl}/profile/patient/${id}/delete`);
  }

  addTag(patientId: TGuid, tagId: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Patients/${patientId}/tags/${tagId}`);
  }

  removeTag(patientId: TGuid, tagId: TGuid): Observable<void> {
    return this.apiService.post(
      `${environment.environmentVariables.apiCoreUrl}/Patients/${patientId}/tags/${tagId}/remove`,
    );
  }

  setName(firstName: string, lastName: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Patients/me/name`, {
      first_name: firstName,
      last_name: lastName,
    });
  }

  setCPF(cpf: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Patients/me/cpf`, {
      cpf,
    });
  }

  setGender(gender: EGenderDTO): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Patients/me/gender`, { gender });
  }

  setDateOfBirth(dob: TDateFormattedString): Observable<void> {
    const date = DateFormatter.stringToDate(dob, { format: DEFAULT_DATE_FORMAT });
    const isoDate = DateFormatter.dateToString(date, { ignoreTimezones: true });

    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Patients/me/dateBirth`, {
      date_birth: isoDate,
    });
  }

  sendForm(
    firstName: string,
    lastName: string,
    mothersName: string,
    cpf: string,
    gender: EGenderDTO,
    dob: TDateFormattedString,
    address: IAddress,
  ): Observable<void> {
    const date = DateFormatter.stringToDate(dob, { format: DEFAULT_DATE_FORMAT });
    const isoDate = DateFormatter.dateToString(date, { ignoreTimezones: true });

    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Patients/form`, {
      date_birth: isoDate,
      first_name: firstName,
      last_name: lastName,
      mothers_name: mothersName,
      cpf,
      gender,
      country_id: address.country_id,
      zip_code: address.zip_code,
      state_id: address.state_id,
      city: address.city,
      district: address.district,
      street: address.street,
      // eslint-disable-next-line id-blacklist
      number: address.number,
      complement: address.complement,
      address: address.address,
    });
  }

  update(id: TGuid, model: IFullUpdatePatientDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiCoreUrl}/Patients/${id}`, model);
  }

  updateInvite(id: TGuid, model: IFullUpdatePatientDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiAuthUrl}/invites/patient/${id}`, model);
  }

  uploadAvatarPatient(file: File, extension: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, 'file.' + extension);
    return this.apiService.postWithForm<string>(
      `${environment.environmentVariables.apiCoreUrl}/Patients/me/avatar`,
      formData,
    );
  }

  getEmbeddedUrl(patientProfileId: TGuid): Observable<{ embedded_url: string }> {
    return this.apiService.post<{ embedded_url: string }>(
      `${environment.environmentVariables.apiAuthUrl}/embedded/patients/authenticate`,
      {
        patient_profile_id: patientProfileId,
      },
    );
  }

  //TODO: In the next version will be good to create a Iframe isolated component inseted of window.open.
  showImpersonateWindow(urlObj: { embedded_url: string }): void {
    const formattedUrl = this.formatUrl(urlObj.embedded_url);

    const width = screen.availWidth;
    const height = screen.availHeight;
    const leftPosition = 0;
    const topPosition = 0;

    const auth = JSON.parse(this.localStorageService.getItem('auth'));
    this.localStorageService.store('openedBy', auth?.userType);

    const windowName = 'impersonateWindow';

    const newWindow = window.open(
      formattedUrl,
      windowName,
      `width=${width},height=${height},resizable=yes,scrollbars=yes,left=${leftPosition},top=${topPosition},toolbar=no,menubar=no,location=no,directories=no`,
    );

    const checkWindowClosed = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkWindowClosed);
        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith('_embedded_')) {
            localStorage.removeItem(key);
          }
        });
      }
    }, 1000);
  }

  private formatUrl(embeddedUrl: string): string {
    let newUrl = embeddedUrl;
    if (window.location.host.includes('localhost')) {
      newUrl = embeddedUrl.replace('https://dev.app.sosportal.com.br', 'http://localhost:4200');
    }
    return newUrl;
  }
}
