<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
  </head>
  <body>
    <header>
      <div class="header-container-external-files">
        <h1>{{ "title-send-documents" | translate }}</h1>
        <hr class="upload-external-hr" />
        <img
          *ngIf="!isPiaui; else otherLogo"
          [src]="soslogo"
          [ngClass]="{ 'small-img': isLoading, 'normal-img': !isLoading }"
          alt="sos-portal"
        />
        <ng-template #otherLogo>
          <img [src]="piauilogo" [ngClass]="{ 'small-img': isLoading, 'normal-img': !isLoading }" alt="piaui" />
        </ng-template>
      </div>
    </header>
    <div *ngIf="isLoading" class="loading-overlay">
      <i class="fas fa-spinner fa-spin"></i>
      <p>{{ "loading-info-back" | translate }}</p>
    </div>
    <main *ngIf="!isLoading" class="upload-external-files-main">
      <div>
        <h3 style="font-weight: bold;">{{ "submission.in.progress" | translate }}</h3>
      </div>
      <div class="send-external-files">
        <h4 style="font-weight: bold;">{{ "button.send.file" | translate }}</h4>
        <p>{{ "file.size.limit" | translate }}</p>
      </div>
      <div class="send-external-files-buttons">
        <button class="upload-external-files-photo-button" (click)="openCamera()">
          {{ "button.send.photo" | translate }}
        </button>
        <button class="upload-external-files-file-button" (click)="triggerFileInput()">
          {{ "button.send.file" | translate }}
        </button>
        <input type="file" id="fileInput" (change)="handleFileInput($event)" style="display: none;" multiple />
        <input
          type="file"
          id="photoInput"
          (change)="handlePhotoInput($event)"
          style="display: none;"
          accept="image/*"
          capture="environment"
        />
      </div>
      <div class="upload-external-files-container" id="files-container"></div>
      <div class="confirm-upload-external-files-container">
        <button class="cancel-upload-external-files" (click)="clearFiles()">
          {{ "button.cancel.upload" | translate }}
        </button>
        <button class="confirm-upload-external-files" (click)="SendFiles()">
          {{ "confirm.send.file" | translate }}
        </button>
      </div>
    </main>
  </body>
</html>
