import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { loadLocaleTranslations } from './locale-initializer';
import { LocaleService } from './locale-getter';

@Injectable({
  providedIn: 'root',
})
export class LocaleInitializerGuard implements CanActivate {
  constructor(private localeService: LocaleService) {}

  canActivate(): Promise<true> {
    const locale = this.localeService.getCurrentLocale();
    return loadLocaleTranslations(locale).then(() => true);
  }
}
