import { Injectable } from '@angular/core';
import { EProfileTypeDTO, IRequisitionNotification } from '@project/view-models';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequisitionNotificationCenterApiProviderService } from 'src/app/project/data-providers/api-providers/requisition-notification-center-api-provider.service';
import { LocalStorageService } from 'src/app/project/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserInformationCenterManagerService {
  public notifications = new BehaviorSubject<IRequisitionNotification[]>([]);
  public notifications$ = this.notifications.asObservable();

  public isPatient: boolean;

  public unreadNotifications$ = this.notifications$.pipe(
    map((notifications) => {
      if (notifications.some((notification) => notification.read_at === null)) {
        return notifications;
      } else {
        return [];
      }
    }),
  );

  constructor(
    private requisitionNotificationCenterApiProviderService: RequisitionNotificationCenterApiProviderService,
    private localStorageService: LocalStorageService,
  ) {
    try {
      const auth = JSON.parse(this.localStorageService.getItem('auth'));
      this.isPatient = auth.userType === EProfileTypeDTO.Patient;
    } catch (error) {
      console.error('Error parsing auth data from localStorage:', error);
      this.isPatient = false;
    }

    if (this.isPatient) {
      this.loadNotifications();
    }
  }

  public loadNotifications() {
    this.requisitionNotificationCenterApiProviderService.getLatest().subscribe((notifications) => {
      this.notifications.next(notifications);
    });
  }

  public markNotificationAsRead(notification: IRequisitionNotification) {
    return this.requisitionNotificationCenterApiProviderService.markNotificationAsRead(notification.id);
  }
}
