import { monitorLogger } from '../monitor-logger';

export function handlerDOMContentLoaded() {
  const navigationEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];

  if (navigationEntries.length > 0) {
    const navigationEntry = navigationEntries[0];

    if (navigationEntry.type === 'reload') {
      monitorLogger.app.track('[DOM][RELOADED]', 'reload');
    } else if (navigationEntry.type === 'back_forward') {
      monitorLogger.app.track('[DOM][BACK_FORWARD]', 'back_forward');
    } else if (navigationEntry.type === 'navigate') {
      monitorLogger.app.track('[DOM][LOADED]', 'navigate');
    } else {
      monitorLogger.app.track('[DOM][OTHER]', 'other');
    }
  }
}
