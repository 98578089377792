import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelper } from './api.helper';
import { monitorLogger } from '../../project/monitor/monitor-logger';

interface IPagingQueryParams {
  limit: number;
  offset: number;
}

export interface IPagingResponse<T> {
  items: T[];
  totalItemsCount: number;
  requestTotalItemsCount: number;
  itemsPerPage: number;
  requestTotalPages: number;
}

interface IRequestParams<RawItem, TargetItem> {
  url: string;
  pageNumber: number;
  itemsPerPage: number;
  itemFactory: (item: RawItem) => TargetItem;
  additionalQueryParams?: Record<string, any>;
}

@Injectable({
  providedIn: 'root',
})
export class PagingApiService {
  private static DEFAULT_MAX_ITEMS_PER_PAGE = 2_147_483_647; // Value from swagger

  protected constructor(protected http: HttpClient) {}

  public request<RawItem, TargetItem>(
    params: IRequestParams<RawItem, TargetItem>,
  ): Observable<IPagingResponse<TargetItem>> {
    let itemsPerPage = params.itemsPerPage;
    let pageNumber = params.pageNumber;

    if (itemsPerPage === Infinity || itemsPerPage <= 0 || pageNumber <= 0) {
      itemsPerPage = PagingApiService.DEFAULT_MAX_ITEMS_PER_PAGE;
      pageNumber = 1;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const requestQueryParams = ApiHelper.convertObjectToHttpParams({
      ...(params.additionalQueryParams || {}),
      ...this.buildRequestQueryParams(pageNumber, itemsPerPage),
    });

    monitorLogger.http.request('GET', params.url, null);

    return this.http
      .get(params.url, {
        headers,
        params: requestQueryParams,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<RawItem[]>) => {
          monitorLogger.http.response('GET', response.status, response.url, response.body);
          return this.buildResponseData<RawItem, TargetItem>(
            response.body,
            params.itemFactory,
            itemsPerPage,
            response.headers,
          );
        }),
      );
  }

  private buildResponseData<R, T>(
    rawItems: R[],
    factory: (item: R) => T,
    itemsPerPage,
    responseHeaders: HttpHeaders,
  ): IPagingResponse<T> {
    const totalItemsCount = +responseHeaders.get('ptm-total-count');
    const pagesTotalCount = Math.ceil(totalItemsCount / itemsPerPage);

    return {
      items: rawItems.map((item) => factory(item)),
      itemsPerPage,
      requestTotalItemsCount: totalItemsCount, // TODO implement
      totalItemsCount,
      requestTotalPages: pagesTotalCount,
    };
  }

  private buildRequestQueryParams(pageNumber: number, itemsPerPage: number): IPagingQueryParams {
    return {
      limit: itemsPerPage,
      offset: (pageNumber - 1) * itemsPerPage,
    };
  }
}
