import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBasicDirective } from '../input-basic.directive';

export type TInputAppearance = 'default' | 'white';
export type TInputSize = 'l' | 'm';

@Component({
  selector: 'lib-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends InputBasicDirective<string> implements OnInit, ControlValueAccessor {
  @Input() trim: boolean;
  @Input() type = 'text';
  @Input() autocomplete: string;
  @Input() placeholder: string;
  @Input() mask?: string;

  @Input() appearance: TInputAppearance = 'default';

  public showPasswordToggle = false;

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  get trimValue(): '' | false {
    return this.trim ? '' : false;
  }

  ngOnInit() {
    this.showPasswordToggle = this.type === 'password';
    super.ngOnInit();
  }

  writeValue(value: string) {
    this._value$.next(value ? value + '' : '');
  }

  togglePasswordType() {
    if (this._disabled$.getValue()) {
      return;
    }
    this.type = this.type === 'password' ? 'text' : 'password';
  }
}
