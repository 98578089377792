import { Injectable } from '@angular/core';
import { RequisitionApiProviderService } from '@project/data-providers';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IRequisitionReason } from '@project/view-models';

@Injectable()
export class ModalReasonsService {
  constructor(
    private notificationsService: NotificationsService,
    private requisitionApiProviderService: RequisitionApiProviderService,
  ) {}

  public sendReason(reason: IRequisitionReason): Observable<void> {
    return Observable.create((observer) => {
      this.requisitionApiProviderService
        .sendReasons(reason)
        .pipe(
          catchError((err, caught) => {
            this.notificationsService.error({
              message: TranslateService.localize('modals.rating.error'),
            });

            observer.complete();

            return caught;
          }),
        )
        .subscribe(() => {
          this.notificationsService.success({
            message: TranslateService.localize('modals.rating.acknowledgment'),
          });

          observer.complete();
        });
    });
  }
}
