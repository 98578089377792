import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HcService } from '@project/services';

@Component({
  selector: 'app-authorization-layout',
  templateUrl: './authorization-layout.component.html',
  styleUrls: ['./authorization-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationLayoutComponent implements OnInit {
  constructor(private hcService: HcService) {}

  showLogo = !(new URL(window.location.href).searchParams.get('provider') === 'embedded');
  public isPiaui: boolean;

  ngOnInit(): void {
    this.isPiaui = this.hcService.isPiaui();
  }
}
