import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { IChatMessage, IRequisition, IRequisitionParticipant } from '@project/view-models';
import { TGuid } from '@core/helpers';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { RequisitionHelpersService, UserProfileDataService } from '@project/services';
import { ChatApiProviderService, ChatDataProvider, ProfileApiProviderService } from '@project/data-providers';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';

export interface IChatMessageFull {
  id: string;
  chatMessage: IChatMessage;
  isMyMessage: boolean;
  sameAuthorAsPrevious: boolean;
  user: IRequisitionParticipant;
}

@Injectable()
export class ChatRoomService {
  private _requisition$ = new BehaviorSubject<IRequisition>(null);
  public requisition$ = this._requisition$.pipe(distinctUntilChanged());

  get chatId(): TGuid | null {
    return this._requisition$.value?.chatId ?? null;
  }

  public chatMessagesFullyLoaded$: Observable<boolean> = this.requisition$.pipe(
    switchMap((requisition) =>
      !requisition.chatId ? of(true) : this.chatDataProvider.subscribeToChatMessagesFullyLoaded(requisition.chatId),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public isChatLoading$: Observable<boolean> = this.requisition$.pipe(
    switchMap((requisition) =>
      !requisition.chatId ? of(false) : this.chatDataProvider.subscribeToChatLoading(requisition.chatId),
    ),
    startWith(null),
    pairwise(),
    switchMap(([prevIsLoading, isLoading]) => {
      if (prevIsLoading === true && isLoading === false) {
        this.chatParticipantsLoading$.next(true);
        return this.chatParticipantsLoading$.pipe(
          filter((isParticipantsLoading) => isParticipantsLoading === false),
          map(() => false),
        );
      }

      return of(isLoading);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public isOffline$ = this.chatDataProvider.isOffline$;

  public chatRoomFullMessages$: Observable<IChatMessageFull[]> = this.requisition$.pipe(
    switchMap((requisition) =>
      combineLatest([of(requisition), this.chatDataProvider.subscribeToChatMessages$(this.chatId)]),
    ),
    this.resolveAllParticipants(),
    tap(() => this.chatParticipantsLoading$.next(false)),
    map(([users, chatMessages]): IChatMessageFull[] =>
      chatMessages.map((message, index, array) => ({
        id: message.uniqueKey,
        chatMessage: message,
        isMyMessage: message.userId === this.currentUserId,
        sameAuthorAsPrevious: array[index - 1]?.userId === message.userId,
        user: users.get(message.userId),
      })),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public isShouldPreventMessageSending$: Observable<boolean> = combineLatest([
    this.isChatLoading$,
    this.isOffline$,
  ]).pipe(map(([isFetching, isOffline]) => isFetching || isOffline));

  private isUploadingFile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUploadingFile$: Observable<boolean> = this.isUploadingFile.asObservable();

  private chatParticipantsCache: Map<TGuid, IRequisitionParticipant> = new Map();
  private chatParticipantsLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private userProfileDataService: UserProfileDataService,
    private chatDataProvider: ChatDataProvider,
    private chatApiProviderService: ChatApiProviderService,
    private notificationsService: NotificationsService,
    private profileApiProviderService: ProfileApiProviderService,
    private requisitionHelpersService: RequisitionHelpersService,
  ) {}

  sendMessage(message: string) {
    this.chatApiProviderService.sendMessage(this.chatId, message).subscribe({
      error: () => {
        this.notificationsService.error({
          message: TranslateService.localize('errors.unable-to-send-chat-message'),
        });
      },
    });
  }

  uploadFile(file: File) {
    this.isUploadingFile.next(true);

    this.chatApiProviderService.uploadFile(this.chatId, file).subscribe({
      error: () => {
        this.notificationsService.error({
          message: TranslateService.localize('chat.attachment.failure'),
        });

        this.isUploadingFile.next(false);
      },
      next: () => {
        this.isUploadingFile.next(false);
      },
    });
  }

  uploadFiles(files: FileList) {
    const MAX_FILE_SIZE_MB = 100;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    this.isUploadingFile.next(true);

    const validFiles = Array.from(files).filter((file) => file.size <= MAX_FILE_SIZE_BYTES);

    if (validFiles.length < files.length) {
      this.notificationsService.error({
        message: TranslateService.localize('chat.attachment.file-too-large'),
      });
    }

    if (validFiles.length === 0) {
      this.isUploadingFile.next(false);
      return;
    }

    const uploadObservables = validFiles.map((file) => this.chatApiProviderService.uploadFile(this.chatId, file));

    forkJoin(uploadObservables).subscribe({
      error: () => {
        this.notificationsService.error({
          message: TranslateService.localize('chat.attachment.failure'),
        });
        this.isUploadingFile.next(false);
      },
      next: () => {
        this.isUploadingFile.next(false);
      },
    });
  }

  getRequisition(): Observable<any> {
    return this.requisition$;
  }
  setRequisition(requisition: IRequisition) {
    this._requisition$.next(requisition);
  }

  loadMoreMessages(): Observable<void> {
    return this.chatDataProvider.loadPreviousMessagesPage(this.chatId);
  }

  private get currentUserId(): TGuid {
    return this.userProfileDataService.profile?.id;
  }

  private resolveAllParticipants(): OperatorFunction<
    [IRequisition, IChatMessage[]],
    [Map<TGuid, IRequisitionParticipant>, IChatMessage[]]
  > {
    return switchMap(
      ([requisition, chatMessages]): Observable<[Map<TGuid, IRequisitionParticipant>, IChatMessage[]]> => {
        const unknownUserIds: TGuid[] = [];
        chatMessages.forEach((message) => {
          if (this.chatParticipantsCache.has(message.userId)) {
            return;
          }

          const participantFromRequisition = this.requisitionHelpersService.getParticipantById(
            requisition,
            message.userId,
          );
          if (participantFromRequisition) {
            this.chatParticipantsCache.set(message.userId, participantFromRequisition);
            return;
          }

          if (!unknownUserIds.includes(message.userId)) {
            unknownUserIds.push(message.userId);
          }
        });

        const unknownUsers$: Observable<IRequisitionParticipant>[] = unknownUserIds.map((id) =>
          this.profileApiProviderService.getRequisitionParticipantProfile(id),
        );

        return (unknownUsers$.length
          ? forkJoin(unknownUsers$).pipe(
              tap((participants) =>
                participants.forEach((participant) => this.chatParticipantsCache.set(participant.id, participant)),
              ),
              map(() => null),
            )
          : of(null)
        ).pipe(map(() => [this.chatParticipantsCache, chatMessages]));
      },
    );
  }
}
