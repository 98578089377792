import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadExternalFilesComponent } from './upload-external-files/upload-external-files.component';
import { TranslateModule } from '@project/translate';
import { ModalReasonsService } from '../../project/components/modal-reasons/modal-reasons.service';

@NgModule({
  declarations: [UploadExternalFilesComponent],
  imports: [CommonModule, TranslateModule],
  providers: [ModalReasonsService],
})
export class UploadExternalFilesModule {}
