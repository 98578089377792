import { Injectable } from '@angular/core';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import { catchError, map, tap } from 'rxjs/operators';
import {
  DoctorViewModelFactory,
  IDoctor,
  IDoctorDTO,
  IScheduleGroup,
  IScheduleGroupDTO,
  ScheduleGroupViewModelFactory,
  IFullUpdateDoctorDTO,
  License,
  DoctorTesteViewModelFactory,
  IDoctorSchedule,
  IDoctorFilterSchedule,
  ICounter,
  IDoctorHealthCenterDTO,
  IDoctorHealthCenterQueueDTO,
  IDoctorBasicDataDTO,
  IMemedAuthDTO,
} from '@project/view-models';
import { HttpErrorResponse } from '@angular/common/http';
import { validateDoctorFields } from '../../../lazy-modules/doctors/validate-doctor-data.helper';
import { NotificationsService } from '@lib/notifications';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DoctorsApiProviderService {
  constructor(
    private apiService: ApiService,
    private pagingApiService: PagingApiService,
    private notificationService: NotificationsService,

    private localStorageService: LocalStorageService,
  ) {}

  private _disabledRecipeDigitalButton$ = new BehaviorSubject<boolean>(false);
  public disabledRecipeDigitalButton$ = this._disabledRecipeDigitalButton$.asObservable();

  getCounters(): Observable<ICounter> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/Counters`);
  }

  getAll(params?: { withScheduleOnly?: boolean; tagId?: string }): Observable<IDoctor[]> {
    const queryParams = {
      schedule: params?.withScheduleOnly ?? null,
      tag: params?.tagId ?? null,
    };

    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Doctors`, queryParams)
      .pipe(map((users) => users.map((user) => DoctorViewModelFactory.createFromDTO(user))));
  }

  getAllHealthCenters(doctorId: TGuid): Observable<IDoctorHealthCenterDTO[]> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/Doctors/${doctorId}/hc`);
  }

  getAllHealthCentersQueue(): Observable<IDoctorHealthCenterQueueDTO[]> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/Requisition/queue/hcs/info`);
  }

  getAllDoctorsFilters(filters: IDoctorFilterSchedule): Observable<IDoctorSchedule[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Doctors/GetAllBySpec`, filters)
      .pipe(map((users) => users.map((user) => DoctorTesteViewModelFactory.createFromDTO(user))));
  }

  getAllDoctorsSchedulle(): Observable<IDoctorSchedule[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Doctors/GetAllBySpec`)
      .pipe(map((users) => users.map((user) => DoctorTesteViewModelFactory.createFromDTO(user))));
  }

  getById(id: TGuid): Observable<IDoctor> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Doctors/${id}`)
      .pipe(map((user) => DoctorViewModelFactory.createFromDTO(user)));
  }

  getPaged(page: number, itemsPerPage: number, query?: Record<string, any>): Observable<IPagingResponse<IDoctor>> {
    /* TASK URGENTE: PRECISA SER REFEITA IMEDIATAMENTE */
    this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Counters`)
      .toPromise()
      .then((data) => {
        this.localStorageService.store('counters-queue', JSON.stringify(data.queue));
        this.localStorageService.store('counters-online_doctors_busy', JSON.stringify(data.online_doctors_busy));
        this.localStorageService.store('counters-average_waiting_time', JSON.stringify(data.average_waiting_time));
        this.localStorageService.store('counters-total_requisitions', JSON.stringify(data.total_requisitions));
        this.localStorageService.store('counters-able_to_work_doctors', JSON.stringify(data.able_to_work_doctors));
      });
    return this.pagingApiService.request<IDoctorDTO, IDoctor>({
      url: `${environment.environmentVariables.apiCoreUrl}/Doctors`,
      additionalQueryParams: query,
      itemsPerPage,
      itemFactory: (item) => DoctorViewModelFactory.createFromDTO(item),
      pageNumber: page,
    });
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiAuthUrl}/profile/doctor/${id}/delete`);
  }

  update(id: TGuid, model: IFullUpdateDoctorDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiCoreUrl}/Doctors/${id}`, model);
  }

  updateInvite(id: TGuid, model: IFullUpdateDoctorDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiAuthUrl}/invites/doctor/${id}`, model);
  }

  getScheduleByDoctorId(id: TGuid): Observable<IScheduleGroup[]> {
    return this.apiService
      .get<IScheduleGroupDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Doctors/${id}/schedule`)
      .pipe(map((items) => items.map((item) => ScheduleGroupViewModelFactory.createGetViewModel(item))));
  }

  validateLicense(license: License): Observable<any> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Doctors/validate/crm`, license);
  }

  addTag(doctorId: TGuid, tagId: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Doctors/${doctorId}/tags/${tagId}`);
  }

  removeTag(doctorId: TGuid, tagId: TGuid): Observable<void> {
    return this.apiService.post(
      `${environment.environmentVariables.apiCoreUrl}/Doctors/${doctorId}/tags/${tagId}/remove`,
    );
  }

  uploadAvatarDoctor(file: File, extension: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, 'file.' + extension);
    return this.apiService.postWithForm<string>(
      `${environment.environmentVariables.apiCoreUrl}/Doctors/me/avatar`,
      formData,
    );
  }

  async getMemedToken() {
    const doctorBasicData = await this.apiService
      .get<IDoctorBasicDataDTO>(`${environment.environmentVariables.apiCoreUrl}/Doctors/basic/data`)
      .toPromise();

    if (!validateDoctorFields(doctorBasicData, this.notificationService)) {
      return;
    }

    const doctorCPF = doctorBasicData.cpf.replace(/\D/g, '');

    try {
      return await this.apiService
        .get<IMemedAuthDTO>(`${environment.environmentVariables.apiMemedUrl}/Auth/token?cpf=${doctorCPF}`)
        .toPromise();
    } catch (error) {
      return await this.apiService
        .post<IMemedAuthDTO>(`${environment.environmentVariables.apiMemedUrl}/Auth/token`, {
          doctorId: doctorBasicData.doctor_id,
          name: doctorBasicData.name,
          lastName: doctorBasicData.last_name,
          dateOfBird: doctorBasicData.date_of_bird,
          cpf: doctorBasicData.cpf.replace(/\D/g, ''),
          crm: doctorBasicData.crm,
          uf: doctorBasicData.uf,
          healthCenterId: doctorBasicData.health_center_id,
          specialistId: doctorBasicData.specialist_id,
        })
        .toPromise();
    }
  }

  cpfIsValid(): Observable<void> {
    const storage = JSON.parse(this.localStorageService.getItem('auth'));
    const userType = storage?.userType;

    if (userType === 'Doctor') {
      return this.apiService.get<boolean>(`${environment.environmentVariables.apiCoreUrl}/Doctors/cpf/isvalid`).pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }),
        tap((cpfIsValid) => this._disabledRecipeDigitalButton$.next(!cpfIsValid)),
        map(() => null),
      );
    } else {
      this._disabledRecipeDigitalButton$.next(true);
      return null;
    }
  }
}
