import { Injectable } from '@angular/core';
import { getEnumValues } from '@core/helpers';
import { EDoctorSpecializationDTO, getDoctorSpecializationLocalization } from '@project/view-models';
import { ISelectOption } from '@lib/ui/form-elements';

@Injectable({
  providedIn: 'root',
})
export class DoctorSpecializationDataProvider {
  // TODO: get from backend.
  private specializations = [
    { id: 0, code: EDoctorSpecializationDTO.Infectologist },
    { id: 1, code: EDoctorSpecializationDTO.GeneralPhysician },
    { id: 2, code: EDoctorSpecializationDTO.Pulmonologist },
    { id: 3, code: EDoctorSpecializationDTO.Cardiologist },
    { id: 4, code: EDoctorSpecializationDTO.Intensivist },
    { id: 5, code: EDoctorSpecializationDTO.Dermatologist },
    { id: 6, code: EDoctorSpecializationDTO.Physiotherapist },
    { id: 7, code: EDoctorSpecializationDTO.Psychologist },
    { id: 8, code: EDoctorSpecializationDTO.Psychiatrist },
    { id: 9, code: EDoctorSpecializationDTO.VascularSurgeon },
    { id: 10, code: EDoctorSpecializationDTO.Endocrinologist },
    { id: 11, code: EDoctorSpecializationDTO.Gastroenterologist },
    { id: 12, code: EDoctorSpecializationDTO.Gynecologist },
    { id: 13, code: EDoctorSpecializationDTO.Obstetric },
    { id: 14, code: EDoctorSpecializationDTO.Hematologist },
    { id: 15, code: EDoctorSpecializationDTO.Nephrologist },
    { id: 16, code: EDoctorSpecializationDTO.Neurologist },
    { id: 17, code: EDoctorSpecializationDTO.Nutritionist },
    { id: 18, code: EDoctorSpecializationDTO.Ophthalmologist },
    { id: 19, code: EDoctorSpecializationDTO.Orthopedist },
    { id: 20, code: EDoctorSpecializationDTO.Otolaryngologist },
    { id: 21, code: EDoctorSpecializationDTO.Pediatrician },
    { id: 22, code: EDoctorSpecializationDTO.ChildPsychiatrist },
    { id: 23, code: EDoctorSpecializationDTO.Rheumatologist },
    { id: 24, code: EDoctorSpecializationDTO.Traumatologist },
    { id: 25, code: EDoctorSpecializationDTO.Urologist },
    { id: 26, code: EDoctorSpecializationDTO.GeneralSurgeon },
  ];

  // It was request to temporally reduce the list of specializations
  private short_specializations = [
    { id: 1, code: EDoctorSpecializationDTO.GeneralPhysician },
    { id: 5, code: EDoctorSpecializationDTO.Dermatologist },
    { id: 12, code: EDoctorSpecializationDTO.Gynecologist },
    { id: 8, code: EDoctorSpecializationDTO.Psychiatrist },
    { id: 7, code: EDoctorSpecializationDTO.Psychologist },
    { id: 16, code: EDoctorSpecializationDTO.Neurologist },
    { id: 25, code: EDoctorSpecializationDTO.Urologist },
    { id: 3, code: EDoctorSpecializationDTO.Cardiologist },
    { id: 10, code: EDoctorSpecializationDTO.Endocrinologist },
    { id: 21, code: EDoctorSpecializationDTO.Pediatrician },
    { id: 19, code: EDoctorSpecializationDTO.Orthopedist },
    { id: 17, code: EDoctorSpecializationDTO.Nutritionist },
  ];

  getSelectOptions(isShortSpecialization: boolean = false) {
    const specializationsList = isShortSpecialization ? this.short_specializations : this.specializations;

    const options = specializationsList.map((specialization) => ({
      value: specialization.id.toString(),
      label: getDoctorSpecializationLocalization(specialization.code),
    }));

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }
}
