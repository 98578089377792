<div class="container">
  <div class="main-container">
    <div class="video-container">
      <div class="main-video-window" id="main-video-window">
        <ng-container *ngIf="!(videoconference.remoteVideoObservable() | async)">
          <ng-container
            *ngTemplateOutlet="videoconference.getRemoteParticipants().length > 0 ? mainVideoOff : waitingParticipant"
          ></ng-container>
        </ng-container>
      </div>

      <div class="secondary-video-window">
        <div class="secondary-windows-box">
          <ng-container *ngIf="!(videoconference.localVideoObservable() | async)">
            <ng-container *ngTemplateOutlet="secundaryVideoOff"></ng-container>
          </ng-container>

          <div
            [ngClass]="(videoconference.localVideoObservable() | async) ? 'mine-video-window' : 'video-none'"
            id="mine-video-window"
          ></div>
        </div>
      </div>

      <ul class="actions">
        <li>
          <button
            class="control"
            id="audio-button"
            [class.active]="videoconference.localAudioObservable() | async"
            (click)="toggleAudio()"
          >
            <lib-icon
              [type]="(videoconference.localAudioObservable() | async) ? 'mic-white' : 'mic-off-white'"
            ></lib-icon>
          </button>
        </li>

        <li>
          <button
            class="control"
            id="video-button"
            [class.active]="videoconference.localVideoObservable() | async"
            (click)="toggleVideo()"
          >
            <lib-icon
              [type]="(videoconference.localVideoObservable() | async) ? 'videocam-white' : 'videocam-off-white'"
            ></lib-icon>
          </button>
        </li>

        <li>
          <button class="control" id="call-button" [class.active]="false" (click)="left()">
            <lib-icon [type]="'call-end-white'"></lib-icon>
          </button>
        </li>

        <li *ngIf="disableChatObservable$ | async">
          <button class="control" id="chat-button" [class.active]="showChat" (click)="toggleChat()">
            <lib-icon [type]="'chat-white'"></lib-icon>
          </button>
        </li>
        <li>
          <button *ngIf="isPatient" class="upload-documents" (click)="openModalQrCode()">
            {{ "title-send-documents" | translate }}
          </button>
        </li>
      </ul>
    </div>

    <div class="chat-container" *ngIf="showChat">
      <app-chat-room [requisition]="requisition"></app-chat-room>
    </div>
  </div>

  <ng-template #mainVideoOff>
    <div class="avatar-window">
      <div class="avatar-participant-name">
        {{ mainProfileTypeDTO.fullName }}
      </div>
      <app-avatar-preview [profileType]="mainProfileTypeDTO?.profileType" [url]="mainProfileTypeDTO?.photoUrl">
      </app-avatar-preview>
    </div>

    <ng-container *ngIf="videoconference.remoteFallbackObservable() | async">
      <ng-container *ngTemplateOutlet="fallback"></ng-container>
    </ng-container>
  </ng-template>

  <ng-template #secundaryVideoOff>
    <div class="avatar-window">
      <div class="avatar-participant-name">
        {{ secundaryProfileTypeDTO.fullName }}
      </div>
      <app-avatar-preview
        [profileType]="secundaryProfileTypeDTO?.profileType"
        [url]="secundaryProfileTypeDTO?.photoUrl"
      >
      </app-avatar-preview>
    </div>
  </ng-template>

  <ng-template #waitingParticipant>
    {{ "video-calls.status-messages.waiting-participant" | translate }}...
  </ng-template>

  <ng-template #fallback>
    <div class="fallback">
      <span>
        {{ "video-calls.fallback.message1" | translate }}
      </span>
      <span>
        {{ "video-calls.fallback.message2" | translate }}
      </span>
      <span>
        {{ "video-calls.fallback.message3" | translate }}
      </span>
    </div>
  </ng-template>
</div>
