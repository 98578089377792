import { IRequisition, IRequisitionParticipant, ITimeSlot } from '@project/view-models';

export enum EPatientSessionAction {
  CloseRequisition = 'CloseRequisition',
}

export interface IRequisitionWithParticipantAndAppointment extends IRequisition {
  participant: IRequisitionParticipant | null;
  appointment: ITimeSlot | null;
}

type AllowedValue = {
  id: string;
};

export type QuestionnaireAnswer = {
  question_id: string;
  allowed_values: AllowedValue[];
};

export const questionnaireAnswers: QuestionnaireAnswer[] = [
  {
    question_id: 'e1724d27-8482-473b-be14-a704c52c5b33',
    allowed_values: [
      {
        id: '630cdce3-8a04-4125-a020-2988b4fceff3',
      },
    ],
  },
  {
    question_id: '33adf6c6-7b53-446b-9544-09299a937cb9',
    allowed_values: [
      {
        id: '0420966d-75b1-4f9d-b679-432cbcf73cf6',
      },
    ],
  },
  {
    question_id: '11a8e0dc-e25b-487d-9095-e302e353b607',
    allowed_values: [
      {
        id: '0420966d-75b1-4f9d-b679-432cbcf73cf6',
      },
    ],
  },
];

export const questionnaireId = 'eee30e26-d8b4-4b0a-89a0-34648c74c185';
