import { AgoraData, FEATURE_CALL, FEATURE_SOUND, FEATURE_VIDEO, VideoConferenceProvider } from '../definitions';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  RemoteStreamFallbackType,
} from 'agora-rtc-sdk-ng';
import { AgoraSettings } from '../props';
import { BehaviorSubject } from 'rxjs';
import { AIDenoiserExtension, AIDenoiserProcessor, AIDenoiserProcessorMode } from 'agora-extension-ai-denoiser';

export type AgoraMediaType = 'audio' | 'video' | 'datachannel';

export class AgoraProvider extends VideoConferenceProvider {
  public name = 'Agora Provider';

  private localMicrophoneAudioTrack: IMicrophoneAudioTrack;
  private localCameraVideoTrack: ICameraVideoTrack;

  private client: IAgoraRTCClient = AgoraRTC.createClient(AgoraSettings.clientOptions);
  private denoiser = new AIDenoiserExtension({ assetsPath: AgoraSettings.denoiserPath });
  private hasCamera: boolean;
  private hasSupportDenoiser: boolean;

  private remoteVideoBehaviorSubject$ = new BehaviorSubject<boolean>(false);
  private remoteAudioBehaviorSubject$ = new BehaviorSubject<boolean>(false);

  public remoteVideoObservable = this.remoteVideoBehaviorSubject$.asObservable();
  public remoteAudioObservable = this.remoteAudioBehaviorSubject$.asObservable();

  private localVideoBehaviorSubject$ = new BehaviorSubject<boolean>(false);
  private localAudioBehaviorSubject$ = new BehaviorSubject<boolean>(false);

  public localVideoObservable = this.localVideoBehaviorSubject$.asObservable();
  public localAudioObservable = this.localAudioBehaviorSubject$.asObservable();

  private remoteFallbackSubject = new BehaviorSubject<boolean>(false);
  public remoteFallbackObservable = this.remoteFallbackSubject.asObservable();

  constructor(private agoraData: AgoraData) {
    super();

    this.setup();
    this.registerDenoiser();
    this.enableLogs();
  }

  async join(): Promise<void> {
    await this.enableMediaStreamFallback();
    await this.joinChannel(this.agoraData);
  }

  async left(): Promise<void> {
    await this.leaveChannel();
    this.client.removeAllListeners();
  }

  features(): string[] {
    return [FEATURE_VIDEO, FEATURE_SOUND, FEATURE_CALL];
  }

  getRemoteParticipants(): (string | number)[] {
    return this.client.remoteUsers.map((user) => user.uid);
  }

  async toggleVideo(): Promise<void> {
    if (!this.hasCamera) {
      return Promise.resolve();
    }

    const isPlaying = this.localCameraVideoTrack.isPlaying;

    await this.localCameraVideoTrack.setEnabled(!isPlaying);
    this.localVideoBehaviorSubject$.next(!isPlaying);
  }

  async toggleSound(): Promise<void> {
    const isMuted = this.localMicrophoneAudioTrack.muted;

    await this.localMicrophoneAudioTrack.setMuted(!isMuted);
    this.localAudioBehaviorSubject$.next(isMuted);
  }

  private setup() {}

  private async joinChannel(data: AgoraData): Promise<void> {
    this.handlerEvents();

    const cameras = await AgoraRTC.getCameras();
    this.hasCamera = cameras.length > 0;

    if (this.hasCamera) {
      await this.startVideoAndAudio(data);
    } else {
      await this.startOnlyAudio(data);
    }
  }

  private async startVideoAndAudio(data: AgoraData): Promise<void> {
    this.localMicrophoneAudioTrack = await this.createMicrophoneAudioTracker();

    this.localCameraVideoTrack = await this.createCameraVideoTracker();

    if (this.hasSupportDenoiser) {
      await this.applyProcessDenoiser();
    }

    await this.client.join(data.appId, data.channel, data.token, data.uid);
    await this.client.publish([this.localMicrophoneAudioTrack, this.localCameraVideoTrack]);
    const localContainer = this.setupContainer('local');

    this.localCameraVideoTrack.play(localContainer, AgoraSettings.videoConfig);
    this.localVideoBehaviorSubject$.next(true);

    this.localAudioBehaviorSubject$.next(true);
  }

  private async startOnlyAudio(data: AgoraData): Promise<void> {
    this.localMicrophoneAudioTrack = await this.createMicrophoneAudioTracker();

    if (this.hasSupportDenoiser) {
      await this.applyProcessDenoiser();
    }

    await this.client.join(data.appId, data.channel, data.token, data.uid);
    await this.client.publish(this.localMicrophoneAudioTrack);

    this.localVideoBehaviorSubject$.next(false);
    this.remoteVideoBehaviorSubject$.next(false);

    this.localAudioBehaviorSubject$.next(true);
  }

  private async leaveChannel(): Promise<void> {
    this.localMicrophoneAudioTrack.stop();
    this.localMicrophoneAudioTrack.close();

    if (this.hasCamera) {
      this.localCameraVideoTrack.stop();
      this.localCameraVideoTrack.close();

      await this.client.unpublish([this.localMicrophoneAudioTrack, this.localCameraVideoTrack]);
    } else {
      await this.client.unpublish(this.localMicrophoneAudioTrack);
    }

    await this.client.leave();
  }

  private setupContainer(type: string): HTMLElement {
    switch (type) {
      case 'local':
        return document.getElementById(AgoraSettings.container.localName);
      case 'remote':
        return document.getElementById(AgoraSettings.container.remoteName);
      default:
        null;
    }
  }

  async enableMyRemoteVideo(): Promise<void> {
    if (!this.hasCamera) {
      return Promise.resolve();
    }
    await this.client.publish(this.localCameraVideoTrack);
  }

  async disableMyRemoteVideo(): Promise<void> {
    if (!this.hasCamera) {
      return Promise.resolve();
    }
    await this.client.unpublish(this.localCameraVideoTrack);
  }

  private handlerEvents() {
    this.client.on('user-published', async (user, mediaType) => {
      await this.handlerUserPublished(user, mediaType);
    });

    this.client.on('user-unpublished', async (user, mediaType) => {
      await this.handlerUserUnpublished(user, mediaType);
    });

    AgoraRTC.on('camera-changed', async () => {
      const cameras = await AgoraRTC.getCameras();
      this.hasCamera = cameras.length > 0;

      if (this.hasCamera) {
        this.localCameraVideoTrack = await this.createCameraVideoTracker();
      }
    });

    AgoraRTC.on('microphone-changed', async () => {
      const microphones = await AgoraRTC.getMicrophones();

      if (microphones.length > 0) {
        this.localMicrophoneAudioTrack = await this.createMicrophoneAudioTracker();
      }
    });
  }

  private async handlerUserPublished(user: IAgoraRTCRemoteUser, mediaType: AgoraMediaType): Promise<void> {
    await this.client.subscribe(user, mediaType);

    if (mediaType === 'video') {
      const remoteContainer = this.setupContainer('remote');
      user.videoTrack.play(remoteContainer, AgoraSettings.videoConfig);

      this.remoteVideoBehaviorSubject$.next(true);
    }

    if (mediaType === 'audio') {
      this.remoteAudioBehaviorSubject$.next(true);
      user.audioTrack.play();
    }
  }

  private async handlerUserUnpublished(user: IAgoraRTCRemoteUser, mediaType: AgoraMediaType): Promise<void> {
    await this.client.unsubscribe(user, mediaType);

    if (mediaType === 'video') {
      this.remoteVideoBehaviorSubject$.next(false);
    }

    if (mediaType === 'audio') {
      this.remoteAudioBehaviorSubject$.next(false);
    }
  }

  /**
   *
   * level – The output log level.
   *    0: DEBUG. Output all API logs.
   *    1: INFO. Output logs of the INFO, WARNING and ERROR level.
   *    2: WARNING. Output logs of the WARNING and ERROR level.
   *    3: ERROR. Output logs of the ERROR level.
   *    4: NONE. Do not output any log.
   *
   * @see https://docs.agora.io/en/help/integration-issues/log/#web
   */
  private enableLogs() {
    AgoraRTC.enableLogUpload();
    AgoraRTC.setLogLevel(1);
  }

  /**
   *
   * AI Noise Suppression enables you to suppress hundreds of types of noise and reduce distortion
   * in human voices when multiple people speak at the same time. In scenarios such as online meetings,
   * online chat rooms, video consultations with doctors, and online gaming, AI Noise Suppression makes
   * virtual communication as smooth as face-to-face interaction.
   *
   * @see https://docs.agora.io/en/video-calling/advanced-features/ai-noise-suppression?platform=web
   * @private
   */
  private registerDenoiser() {
    this.hasSupportDenoiser = this.denoiser.checkCompatibility();

    if (!this.hasSupportDenoiser) {
      console.error('Does not support AI Denoiser!');
      return;
    }

    AgoraRTC.registerExtensions([this.denoiser]);

    this.denoiser.onloaderror = (e) => {
      console.error(e);
    };
  }

  private async applyProcessDenoiser(): Promise<void> {
    const processor = this.denoiser.createProcessor();
    this.handlerDenoiserOverload(processor);

    this.localMicrophoneAudioTrack.pipe(processor).pipe(this.localMicrophoneAudioTrack.processorDestination);

    await processor.enable();
  }

  private handlerDenoiserOverload(processor: AIDenoiserProcessor) {
    processor.onoverload = async (elapsedTime) => {
      console.log(`overload!!! elapsedTime: ${elapsedTime}`);

      // Switch from AI noise suppression to stationary noise suppression
      await processor.setMode(AIDenoiserProcessorMode.STATIONARY_NS);

      await processor.disable(); // Disable AI noise suppression
    };
  }

  private async createCameraVideoTracker(): Promise<ICameraVideoTrack> {
    return AgoraRTC.createCameraVideoTrack(AgoraSettings.videoTrackerConfig);
  }

  private async createMicrophoneAudioTracker(): Promise<IMicrophoneAudioTrack> {
    return AgoraRTC.createMicrophoneAudioTrack(AgoraSettings.audioTrackerConfig);
  }

  /**
   * In situations with poor network conditions, the quality of audio and video may decrease.
   * When the network is unstable, and it's challenging to maintain both audio and video quality,
   * the Video SDK automatically adjusts by switching the video stream to a lower quality or,
   * if necessary, switches to audio-only mode to ensure better audio quality.
   *
   * @see https://docs.agora.io/en/video-calling/advanced-features/media-stream-fallback?platform=web
   */
  private async enableMediaStreamFallback(): Promise<void> {
    await this.client.enableDualStream();
  }

  /**
   * Enable fallback for use audio only
   */
  private async enableFallbackUser(uid: number | string): Promise<void> {
    await this.client.setStreamFallbackOption(uid, RemoteStreamFallbackType.AUDIO_ONLY);
    await this.disableMyRemoteVideo();
    this.remoteFallbackSubject.next(true);
    console.log(`enableFallbackUser ${uid} RemoteStreamFallbackType.AUDIO_ONLY`);
  }

  /**
   * Disable fallback
   */
  private async disableFallback(uid: number | string): Promise<void> {
    await this.client.setStreamFallbackOption(uid, RemoteStreamFallbackType.DISABLE);
    await this.enableMyRemoteVideo();
    this.remoteFallbackSubject.next(false);
    console.log(`disableFallbackUser ${uid} RemoteStreamFallbackType.DISABLED`);
  }
}
