import { ConstWsSocketMessagesEnum } from '../../shared/ws-socket.messages.enum';

export type TChatSocketMessageType = ESocketMessageTypeDTO | EServiceSocketMessageTypeDTO | ConstWsSocketMessagesEnum;

export enum ESocketMessageTypeDTO {
  ChatMessage = 'message_added',
  UserConnected = 'user_connected',
  UserDisconnected = 'user_disconnected',
  ReceptionistQueueInformation = 'receptionist_queue',
  DoctorsQueueInformation = 'doctors_queue',
  PatientsQueueInformation = 'patients_queue',
  RequisitionInformation = 'requisition_information',
  DoctorStatusUpdate = 'doctor_status',
  ChatCreated = 'chat_created',
  DisconnectByOtherLogin = 'DISCONNECT_THROUGH_MULTIPLE_DEVICES',
  /**
   * @deprecated
   */
  ChatArchived = 'chat_archived',
}

export enum EServiceSocketMessageTypeDTO {
  CallStarted = 'call started',
  CallEnded = 'call ended',
  CallDeclined = 'call declined',
  Awake = 'awake',
}

export enum EAppointmentUpdateMessageStatusDTO {
  Created = 'created',
}
