import { Injectable } from '@angular/core';
import { environment } from '@env';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HcService {
  constructor(private localStorageService: LocalStorageService) {}

  setHealthCenterId(healthCenterId: string): void {
    if (healthCenterId) {
      this.localStorageService.store('healthCenterId', healthCenterId);
    } else {
      this.localStorageService.clear('healthCenterId');
    }
  }

  getHealthCenterId(): string | null {
    return this.localStorageService.getItem('healthCenterId');
  }

  isPiaui(): boolean {
    const healthCenterId = this.getHealthCenterId();
    return healthCenterId === environment.environmentVariables.healthCenterIdPiaui;
  }

  setPiauiHealthCenter(): void {
    this.setHealthCenterId(environment.environmentVariables.healthCenterIdPiaui);
  }

  setNotDefinedYetHealthCenter(): void {
    this.setHealthCenterId('');
  }
}
