import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as platform from 'platform';
import { TGuid } from '@core/helpers';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class InfoDeviceService {
  constructor(private localStorageService: LocalStorageService) {}

  setSpecificDeviceId(deviceId: string) {
    this.localStorageService.store('device-id', deviceId);
  }

  generateDeviceId() {
    const deviceId = uuidv4();
    this.localStorageService.store('device-id', deviceId);
    return deviceId;
  }

  getDeviceId() {
    const deviceId = this.localStorageService.getItem('device-id');
    return !deviceId ? this.generateDeviceId() : deviceId;
  }

  getDeviceInfo() {
    const deviceId = this.getDeviceId();
    const manufacturer = platform.manufacturer || '';
    const device = {
      id: `${deviceId}`,
      system: platform.name,
      version: platform.version,
      name: platform.os.family,
      model: manufacturer,
    };
    return device;
  }
}
