import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { EMediaDevices } from '@core/services';
import { HcService } from '@project/services';

@Component({
  selector: 'app-media-devices-requires-message-overlay',
  templateUrl: './media-devices-requires-message-overlay.component.html',
  styleUrls: ['./media-devices-requires-message-overlay.component.scss'],
})
export class MediaDevicesRequiresMessageOverlayComponent implements IModalComponent, OnInit {
  @Input() mediaDeviceToAsk: EMediaDevices = null;

  constructor(private hcService: HcService) {}

  public readonly mediaDevices = EMediaDevices;

  public submit$ = new EventEmitter();
  public close$ = new EventEmitter();

  public isPiaui: boolean;

  ngOnInit(): void {
    this.isPiaui = this.hcService.isPiaui();
  }
}
