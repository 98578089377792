import { FormComponent } from '@project/shared';
import { Component, EventEmitter, Input, OnDestroy, Output, OnInit } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { ISelectOption } from '@lib/ui/form-elements';
import { RequisitionApiProviderService } from '@project/data-providers';
import { IReason, IRequisitionReason } from '@project/view-models';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { LibValidators, TGuid } from '@core/helpers';
import { TranslateService } from '@project/translate';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal-reasons',
  templateUrl: './modal-reasons.component.html',
  styleUrls: ['./modal-reasons.component.scss'],
})
export class ReasonsComponent implements IModalComponent<IRequisitionReason>, OnInit, OnDestroy {
  reason: IRequisitionReason = {
    requisition_id: '',
    reason_id: '',
    description: '',
  };

  public close$: EventEmitter<any>;
  public submit$: EventEmitter<IRequisitionReason>;

  @Output() submitReason$: EventEmitter<IRequisitionReason> = new EventEmitter();
  @Input() headerTitle: string;

  public isLoading = false;
  public isOther = false;

  public reasons$: BehaviorSubject<ISelectOption[]> = new BehaviorSubject<ISelectOption[]>([]);

  constructor(private requisitionApiProviderService: RequisitionApiProviderService) {
    this.close$ = new EventEmitter();
    this.submit$ = new EventEmitter();
  }

  ngOnInit(): void {
    this.getReasons();
  }

  public getReasons() {
    this.requisitionApiProviderService.getAllReasons().subscribe((reasons: IReason[]) => {
      const select: ISelectOption[] = reasons.map((x: IReason) => ({ value: x.id, label: x.reason }));
      this.reasons$.next(select);
    });
  }

  public valueSelect() {
    const other = '1302602d-388e-4679-9978-1ebcf53294fb';
    const reasonsValue = this.reason.reason_id;
    if (reasonsValue === other) {
      this.isOther = true;
    } else {
      this.isOther = false;
    }
  }

  ngOnDestroy(): void {}

  public close() {
    this.close$.emit();
  }

  public sendRating() {
    if (this.reason.reason_id) {
      this.submitReason$.emit(this.reason);
      this.isLoading = true;
    }
  }
}
