import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedUserFeaturesContainerComponent } from './authorized-user-features-container/authorized-user-features-container.component';
import { RouterModule } from '@angular/router';
import { SessionsSharedModule, UserFeaturesModule } from '@modules';
import { VideoCallModule } from '../modules/video-call/video-call.module';
import { CloseRequisitionsModule } from '@features/modules';
import { ModalSaudeIdMarketplaceModule } from 'src/app/project/components/modal-saudeId-marketplace/modal-saudeId-marketplace.module';
import { ModalQrCodeModule } from '../../project/components/modal-qr-code/modal-qr-code.module';

@NgModule({
  declarations: [AuthorizedUserFeaturesContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    UserFeaturesModule,
    VideoCallModule.forRoot(),
    CloseRequisitionsModule,
    SessionsSharedModule.forRoot(),
    ModalSaudeIdMarketplaceModule,
    ModalQrCodeModule,
  ],
})
export class AuthorizedUserFeaturesContainerModule {}
