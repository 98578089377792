import { License } from './license.view-model';
import { DateFormatter, TDateISOString, TEmail, TGuid } from '@core/helpers';
import { ProfileHelper } from './helpers/profile.helper';
import { EDoctorSpecializationDTO, getDoctorSpecializationLocalization } from './consts/doctor-specialization';
import { EDoctorStatusDTO, getDoctorStatusLocalization } from './consts/doctor-status';
import { EProfileTypeDTO } from './consts/profile-type';
import { EGenderDTO } from './consts/gender';
import { TranslateService } from '@project/translate';
import { IProfileTag, IProfileTagDTO } from './profile-tag.view-model';

export interface IDoctorFilterSchedule {
  doctors?: TGuid;
  tags?: TGuid;
  specs?: string;
}

export interface IFullUpdateDoctorDTO {
  first_name: string;
  last_name: string;
  gender: EGenderDTO;
  specialization: EDoctorSpecializationDTO;
  phone: string;
  date_birth?: Date;
  licenses: License[];
  email: TEmail;
  cpf: string;
}

export interface IDoctorDTO {
  id: TGuid;
  doctor_specialization: EDoctorSpecializationDTO;
  invited: TDateISOString;
  invited_by: TGuid;
  tags: IProfileTagDTO[] | null;
  doctor_licenses: License[];
  first_name: string;
  last_name: string;
  email: TEmail;
  phone: string;
  status: EDoctorStatusDTO;
  photo_url: string | null;
  gender: EGenderDTO;
  birth_date: TDateISOString;
  busy: string;
  color: string;
  hc_disabled: boolean;
  cpf: string;
}

export interface IDoctor {
  id: TGuid;
  specialization: EDoctorSpecializationDTO;
  specializationLabel: string;
  invitedDate: Date;
  invitedBy: TGuid;
  tags: IProfileTagDTO[] | null;
  cpf: string;
  licenses: License[];
  firstName: string;
  lastName: string;
  fullName: string;
  photoUrl: string | null;
  email: TEmail | null;
  phone: string;
  status: EDoctorStatusDTO;
  statusLabel: string;
  profileType: EProfileTypeDTO.Doctor;
  gender: EGenderDTO;
  birthDate: Date | null;
  busy: string;
  color: string;
  hc_disabled: boolean;
}

export interface IDoctorBasicDataDTO {
  doctor_id: string;
  name: string;
  last_name: string;
  date_of_bird: string;
  cpf: string;
  crm: string;
  uf: string;
  health_center_id: string;
  specialist_id: number;
}

export interface IMemedAuthDTO {
  data: {
    success: boolean;
    results: {
      cpf: string;
      doctorId: string;
      healthCenterId: null;
      key: string;
    };
  };
}

export interface ICounter {
  total_requisitions: number;
  active_requisitions: number;
  online_doctors: number;
  away_doctors: number;
  online_doctors_busy: number;
  queue: number;
  average_waiting_time: number;
}
export interface IDoctorSchedule {
  id: TGuid;
  fullName: string;
  specialization: EDoctorSpecializationDTO;
  tags: IProfileTag[];
  checked: boolean;
  color: string;
}

export interface IDoctorScheduleDTO {
  id: TGuid;
  first_name: string;
  last_name: string;
  doctor_specialization: EDoctorSpecializationDTO;
  tags: IProfileTag[];
  color: string;
}

export interface IDoctorHealthCenterDTO {
  health_center_id: TGuid;
  health_center_name: string;
  doctor_id: TGuid;
  status: 'Away' | 'Active';
  in_processing: boolean;
  specialization: string;
  logo_url: string | null;
}

export interface IDoctorHealthCenterQueueDTO {
  health_center_id: TGuid;
  size: number;
  waiting_time: number;
}

export type DoctorSpecialization = {
  specialization: string;
  specialization_id: number;
  specialization_language: string;
};

export class DoctorTesteViewModelFactory {
  static createFromDTO(dto: IDoctorScheduleDTO): IDoctorSchedule {
    return {
      id: dto.id,
      fullName: dto.first_name + ' ' + dto.last_name,
      tags: dto.tags,
      specialization: dto.doctor_specialization,
      checked: false,
      color: dto.color,
    };
  }
}
export class DoctorViewModelFactory {
  static createFromDTO(dto: IDoctorDTO): IDoctor {
    let available = null;
    if (!dto.busy && dto.status === EDoctorStatusDTO.Active) {
      available = TranslateService.localize('busy.no');
    } else if (dto.status === EDoctorStatusDTO.Away) {
      available = '--';
    } else {
      available = TranslateService.localize('busy.yes');
    }

    return {
      id: dto.id,
      email: dto.email,
      firstName: dto.first_name,
      lastName: dto.last_name,
      fullName: ProfileHelper.getFullName(dto.first_name, dto.last_name, dto.email),
      invitedBy: dto.invited_by,
      invitedDate: dto.invited ? DateFormatter.stringToDate(dto.invited) : null,
      tags: dto.tags,
      licenses: dto.doctor_licenses,
      phone: dto.phone,
      status: dto.status,
      specialization: dto.doctor_specialization,
      statusLabel: getDoctorStatusLocalization(dto.status),
      specializationLabel: getDoctorSpecializationLocalization(dto.doctor_specialization),
      profileType: EProfileTypeDTO.Doctor,
      photoUrl: dto.photo_url,
      birthDate: dto.birth_date != null ? new Date(dto.birth_date) : null,
      gender: dto.gender,
      busy: available,
      color: dto.color,
      hc_disabled: dto.hc_disabled,
      cpf: dto.cpf,
    };
  }
}
