import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';

export enum ELocale {
  En = 'en',
  Pt = 'pt',
  Es = 'es',
}

const defaultLocale = ELocale.Pt;
const possibleLocales: string[] = [ELocale.Pt, ELocale.En, ELocale.Es];

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(private localStorageService: LocalStorageService) {}

  getCurrentLocale(): ELocale {
    const userLocale = this.localStorageService.getItem('locale') || navigator.language.slice(0, 2);
    if (possibleLocales.includes(userLocale?.toLowerCase())) {
      return userLocale as ELocale;
    }
    return defaultLocale;
  }
}
