<span class="input-container">
  <lib-input
    class="input"
    [(ngModel)]="inputValue"
    (ngModelChange)="change($event)"
    [disabled]="disabled"
    [appearance]="appearance"
    [size]="size"
    (blurInput)="onBlur($event)"
    [placeholder]="placeholder"
  ></lib-input>
  <lib-icon class="search-icon" [type]="'search'"></lib-icon>
  <span class="spinner-container">
    <lib-spinner *ngIf="pending"></lib-spinner>
  </span>
</span>
