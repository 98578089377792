import { Injectable } from '@angular/core';
import { WebRtcProviderService } from './web-rtc.provider.service';
import { EMediaDevices, MediaDevicesService } from '@core/services';
import { BehaviorSubject, defer, merge, Observable, OperatorFunction, race, Subject, throwError, timer } from 'rxjs';
import { IModalComponentRef, IModalWindowConfig, ModalOverlayService } from '@lib/modal';
import { catchError, debounceTime, filter, first, map, pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AskForMediaDevicesOverlayComponent } from './ask-for-media-devices-overlay/ask-for-media-devices-overlay.component';
import { MediaDevicesRequiresMessageOverlayComponent } from './media-devices-requires-message-overlay/media-devices-requires-message-overlay.component';
import { RatingComponent } from '../../../project/components/modal-rating/modal-rating.component';
import {
  EServiceSocketMessageTypeDTO,
  IProfileFull,
  IRequisition,
  IRequisitionParticipant,
  IServiceMessageEvent,
} from '@project/view-models';
import { TranslateService } from '@project/translate';
import { TGuid } from '@core/helpers';
import { NotificationsService } from '@lib/notifications';
import {
  ChatApiProviderService,
  ConferencesApiProviderService,
  SocketMessagesDataProviderService,
} from '@project/data-providers';
import { RequisitionHelpersService, UserProfileDataService } from '@project/services';
import { ECurrentVideoSessionState, ESessionState, IVideoCallSession } from './consts';
import { ModalRating } from 'src/app/project/components/modal-rating/modal-rating.model';
import { ModalRatingService } from 'src/app/project/components/modal-rating/modal-rating.service';
import { VideoconferenceService } from 'src/app/lazy-modules/videoconference/services';
import { VideoconferenceComponent } from 'src/app/lazy-modules/videoconference/videoconference.component';
import { VideoCallsOverlayComponent } from './video-calls-overlay/video-calls-overlay.component';
import { AGORA, ConferenceData, VOXEET, ZOOM } from 'src/app/lazy-modules/videoconference/definitions';
import { ComponentType } from '@angular/cdk/portal';
import { VideoconferenceImpl } from '../../../lazy-modules/videoconference/videoconference.impl';
import {
  BadGatewayError,
  BadRequestError,
  ClientUnexpectedError,
  ConflictError,
  ForbiddenError,
  GatewayTimeoutError,
  InternalServerError,
  NotFoundError,
  ServerUnexpectedError,
  ServiceUnavailableError,
  TooManyRequestsError,
  UnauthorizedError,
} from 'src/app/core/http/errors';

const REQUEST_MEDIA_OVERLAY_DEBOUNCE_MS: Record<EMediaDevices, number> = {
  [EMediaDevices.Audio]: 100,
  [EMediaDevices.Video]: 3000,
  [EMediaDevices.Both]: 3000,
};

@Injectable()
export class VideoCallManagerService {
  private readonly _sessions$ = new BehaviorSubject<IVideoCallSession[]>([]);

  public readonly mySession$: Observable<IVideoCallSession | null> = this._sessions$.pipe(
    map((sessions) => sessions.find((s) => s.participantId === this.myProfile.id)),
  );

  public readonly participantsSessions$: Observable<IVideoCallSession[]> = this._sessions$.pipe(
    map((sessions) => sessions.filter((s) => s.participantId !== this.myProfile.id)),
  );

  private readonly _sessionState$ = new BehaviorSubject<ESessionState>(ESessionState.Offline);
  public readonly sessionState$ = this._sessionState$.asObservable();
  public readonly isSessionOffline$: Observable<boolean> = this._sessionState$.pipe(
    map((state) => state === ESessionState.Error || state === ESessionState.Offline),
  );

  private readonly _videoSessionState$ = new BehaviorSubject<ECurrentVideoSessionState>(
    ECurrentVideoSessionState.Offline,
  );
  public readonly videoSessionState$ = this._videoSessionState$.asObservable();
  public readonly isVideoSessionOffline$: Observable<boolean> = this._videoSessionState$.pipe(
    map(() => this.isVideoSessionOffline),
  );

  private readonly _isVideoEnabled$ = new BehaviorSubject<boolean>(false);
  public readonly isVideoEnabled$ = this._isVideoEnabled$.asObservable();

  private readonly _isAudioEnabled$ = new BehaviorSubject<boolean>(true);
  public readonly isAudioEnabled$ = this._isAudioEnabled$.asObservable();

  private readonly _isVideoToggleInProgress$ = new BehaviorSubject<boolean>(false);
  public readonly isVideoToggleInProgress$ = this._isVideoToggleInProgress$.asObservable();

  private readonly _isAudioToggleInProgress$ = new BehaviorSubject<boolean>(false);
  public readonly isAudioToggleInProgress$ = this._isAudioToggleInProgress$.asObservable();

  private readonly _requisition$ = new BehaviorSubject<IRequisition>(null);
  public readonly requisition$: Observable<IRequisition> = this._requisition$.asObservable();

  private readonly _openDialog$ = new Subject();
  public readonly openDialog$ = this._openDialog$.asObservable();

  private readonly _closeDialog$ = new Subject();
  public readonly closeDialog$ = this._closeDialog$.asObservable();

  private readonly _preventSessionConnection$ = new Subject();
  private readonly _preventCurrentCallSubscriptions$ = new Subject();

  public _hasCallHappenned = new BehaviorSubject<boolean>(false);
  public readonly hasCallHappenned$ = this._hasCallHappenned.asObservable();

  private readonly _destroy$ = new Subject();

  private modalRef: IModalComponentRef<RatingComponent>;

  private overlayRef: IModalComponentRef<VideoCallsOverlayComponent | VideoconferenceComponent>;

  constructor(
    private webRtcProviderService: WebRtcProviderService,
    private mediaDevicesService: MediaDevicesService,
    private modalOverlayService: ModalOverlayService,
    private notificationsService: NotificationsService,
    private conferencesApiProviderService: ConferencesApiProviderService,
    private userProfileDataService: UserProfileDataService,
    private chatApiProviderService: ChatApiProviderService,
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private requisitionHelpersService: RequisitionHelpersService,
    private modalRatingService: ModalRatingService,
    private videoconferenceService: VideoconferenceService,
    private videoconference: VideoconferenceImpl,
  ) {}

  public get participant(): IRequisitionParticipant | null {
    return this.requisitionHelpersService.getRequisitionParticipantForCurrentUser(this._requisition$.value);
  }

  public get myProfile(): IProfileFull | null {
    return this.userProfileDataService.profile;
  }

  public get requisition(): IRequisition {
    return this._requisition$.value;
  }

  public get isVideoSessionOffline(): boolean {
    return this._videoSessionState$.value === ECurrentVideoSessionState.Offline;
  }

  public get hasVideoSessionDeclined(): boolean {
    return this._videoSessionState$.value === ECurrentVideoSessionState.CallDeclined;
  }

  public readonly participant$: Observable<IRequisitionParticipant | null> = this._requisition$.pipe(
    map((requisition) => this.requisitionHelpersService.getRequisitionParticipantForCurrentUser(requisition)),
  );

  public async endCurrentCall(bySummaryNotes: boolean = false): Promise<void> {
    const exitBySummaryNotes = () => {
      if (
        bySummaryNotes &&
        this.overlayRef &&
        this.overlayRef.instance &&
        this.overlayRef.instance instanceof VideoconferenceComponent
      ) {
        this.overlayRef.instance.exit();
      }
    };
    if (this.isVideoSessionOffline) {
      window.parent?.postMessage('sdk-appointment-end', '*');
      return Promise.resolve()
        .then(() => exitBySummaryNotes())
        .then(() => this._preventSessionConnection$.next())
        .then(() => this._sessionState$.next(ESessionState.Offline))
        .then(() => this._closeDialog$.next())
        .then(() => this.evaluateVideoCallQuality())
        .catch((error) => {
          console.error(`erro endcurrent call bySummaryNotes ${bySummaryNotes}`, error);
        });
    }

    return this.chatApiProviderService
      .sendServiceMessage(this._requisition$.value?.chatId, EServiceSocketMessageTypeDTO.CallEnded)
      .toPromise()
      .then(() => exitBySummaryNotes())
      .then(() => this._preventCurrentCallSubscriptions$.next())
      .then(() => this._preventSessionConnection$.next())
      .then(() => this._sessionState$.next(ESessionState.Offline))
      .then(() => this._closeDialog$.next())
      .then(() => (this.hasVideoSessionDeclined ? null : this.evaluateVideoCallQuality()))
      .then(() => this._videoSessionState$.next(ECurrentVideoSessionState.Offline));
  }

  public resetCallStatus(): void {
    this._hasCallHappenned.next(false);
  }

  public async doCall(requisition: IRequisition): Promise<void> {
    return this.checkDevicesWithRequestOverlay(EMediaDevices.Both)
      .then(() => (requisition?.chatId ? Promise.resolve() : Promise.reject('No Chat Id for requisition')))
      .then(() => this._requisition$.next(requisition))
      .then(() => {
        return this.startCallProvider(requisition);
      });
  }

  public async endCurrentCallLegacy(): Promise<void> {
    if (this.isVideoSessionOffline) {
      window.parent?.postMessage('sdk-appointment-end', '*');
      return Promise.resolve();
    }

    return this.webRtcProviderService
      .leaveCall()
      .catch(() => null /* Will try to end session even if no call existed */)
      .then(() =>
        this.chatApiProviderService
          .sendServiceMessage(this._requisition$.value?.chatId, EServiceSocketMessageTypeDTO.CallEnded)
          .toPromise(),
      )
      .catch(() => null)
      .then(async () => {
        this._preventSessionConnection$.next();

        return this.webRtcProviderService
          .closeSession()
          .catch(() => this._sessionState$.next(ESessionState.Error))
          .then(() => this._sessionState$.next(ESessionState.Offline));
      })
      .catch(() => null /* Even if end session fails will reset all properties */)
      .finally(() => {
        this._videoSessionState$.next(ECurrentVideoSessionState.Offline);
        this._preventCurrentCallSubscriptions$.next();
        this._closeDialog$.next();
        this.evaluateVideoCallQuality();
        window.parent?.postMessage('sdk-appointment-end', '*');
      });
  }

  public toggleAudioLegacy(): void {
    if (this._isAudioToggleInProgress$.value) {
      return;
    }

    this._isAudioToggleInProgress$.next(true);

    this.checkDevicesWithRequestOverlay(EMediaDevices.Audio)
      .then(() => {
        (this._isAudioEnabled$.value ? this.webRtcProviderService.stopAudio() : this.webRtcProviderService.startAudio())
          .then(() => {
            this._isAudioEnabled$.next(!this._isAudioEnabled$.value);
          })
          .catch((error) => {
            this.notifyError(error?.message || TranslateService.localize('video-calls.errors.unable-toggle-audio'));
          })
          .finally(() => {
            this._isAudioToggleInProgress$.next(false);
          });
      })
      .catch(() => {
        this._isAudioToggleInProgress$.next(false);
      });
  }

  public toggleVideoLegacy(): void {
    if (this._isVideoToggleInProgress$.value) {
      return;
    }

    this._isVideoToggleInProgress$.next(true);

    this.checkDevicesWithRequestOverlay(EMediaDevices.Video)
      .then(() => {
        (this._isVideoEnabled$.value ? this.webRtcProviderService.stopVideo() : this.webRtcProviderService.startVideo())
          .then(() => {
            this._isVideoEnabled$.next(!this._isVideoEnabled$.value);
          })
          .catch((error) => {
            this.notifyError(error?.message || TranslateService.localize('video-calls.errors.unable-toggle-video'));
          })
          .finally(() => {
            this._isVideoToggleInProgress$.next(false);
          });
      })
      .catch(() => {
        this._isVideoToggleInProgress$.next(false);
      });
  }

  private async startCallProvider(requisition: IRequisition): Promise<void> {
    try {
      const data: ConferenceData = await this.videoconferenceService.getCredentials().toPromise();

      switch (data.provider?.toLocaleUpperCase() || null) {
        case AGORA:
          this.videoconference.startProvider(data);
          this.openVideoWindow(VideoconferenceComponent);
          return this.startCall(requisition);
        case VOXEET:
          this.openVideoWindow(VideoCallsOverlayComponent);
          return this.startCallLegacy(requisition);
        case ZOOM:
          return Promise.resolve(); //Not implemented yet
        default:
          //This implementation should be changed or updated as soon as Voxeet is cancelled
          this.openVideoWindow(VideoCallsOverlayComponent);
          return this.startCallLegacy(requisition);
      }
    } catch (error) {
      const message = `[${error?.code}] ` + this.getErrorMessage(error);

      this.notifyError(message);

      this._sessionState$.next(ESessionState.Error);
      throw message;
    }
  }

  private getErrorMessage(instanceError: any) {
    if (instanceError instanceof BadRequestError) {
      return TranslateService.localize('errors.400.bad-request.error');
    } else if (instanceError instanceof UnauthorizedError) {
      return TranslateService.localize('errors.401.unauthorized.error');
    } else if (instanceError instanceof ForbiddenError) {
      return TranslateService.localize('errors.403.forbidden.error');
    } else if (instanceError instanceof NotFoundError) {
      return TranslateService.localize('errors.404.not-found.error');
    } else if (instanceError instanceof ConflictError) {
      return TranslateService.localize('errors.409.conflict.error');
    } else if (instanceError instanceof TooManyRequestsError) {
      return TranslateService.localize('errors.429.too-many-requests.error');
    } else if (instanceError instanceof ClientUnexpectedError) {
      return TranslateService.localize('errors.4xx.client-unexpected.error');
    } else if (instanceError instanceof InternalServerError) {
      return TranslateService.localize('errors.500.internal-server.error');
    } else if (instanceError instanceof BadGatewayError) {
      return TranslateService.localize('errors.502.bad-gateway.error');
    } else if (instanceError instanceof ServiceUnavailableError) {
      return TranslateService.localize('errors.503.service-unavailable.error');
    } else if (instanceError instanceof GatewayTimeoutError) {
      return TranslateService.localize('errors.504.gateway-timeout.error');
    } else if (instanceError instanceof ServerUnexpectedError) {
      return TranslateService.localize('errors.5xx.server-unexpected.error');
    } else {
      return instanceError;
    }
  }

  private notifyError(message?: string, duration?: number, title?: string): void {
    this.notificationsService.error({
      title: title || '',
      message: message || TranslateService.localize('nouns.error'),
      durationMs: duration,
    });
  }

  private async startSessionAsPromiseLegacy(): Promise<ESessionState> {
    this._preventSessionConnection$.next();

    return this.isSessionOffline$
      .pipe(
        first((isOffline) => isOffline),
        takeUntil(this._preventSessionConnection$),
      )
      .toPromise()
      .then(() => this._sessionState$.next(ESessionState.Connecting))
      .then(() =>
        this.webRtcProviderService.startSession({
          id: this.myProfile.id,
          name: this.myProfile.fullName,
          avatarUrl: this.myProfile.photoUrl,
        }),
      )
      .then(() => this._sessionState$.next(ESessionState.Online))
      .catch((error) => {
        this.notifyError(error?.message, Infinity);
        this._sessionState$.next(ESessionState.Error);
        throw error;
      })
      .then(() => this._sessionState$.value);
  }

  private async startSessionAsPromise(): Promise<ESessionState> {
    this._preventSessionConnection$.next();

    return this.isSessionOffline$
      .pipe(
        first((isOffline) => isOffline),
        takeUntil(this._preventSessionConnection$),
      )
      .toPromise()
      .then(() => this._sessionState$.next(ESessionState.Connecting))
      .then(() => this._sessionState$.next(ESessionState.Online))
      .then(() => this.notifyParticipantWasLeaveTheCall())
      .catch((error) => {
        this.notifyError(error?.message, Infinity);
        this._sessionState$.next(ESessionState.Error);
        throw error;
      })
      .then(() => this._sessionState$.value);
  }

  private async startCallLegacy(requisition: IRequisition): Promise<void> {
    this._sessions$.next([]);
    this._preventCurrentCallSubscriptions$.next();
    this._openDialog$.next();

    this._videoSessionState$.next(ECurrentVideoSessionState.Offline);

    return this.startSessionAsPromiseLegacy()
      .then(() => this.setControlsDefaultValuesLegacy())
      .then(() => this.initUpdatesLegacy())
      .then(() => this._videoSessionState$.next(ECurrentVideoSessionState.Connecting))
      .then(() => this.webRtcProviderService.startCallLegacy(requisition.chatId))
      .then(() =>
        this.chatApiProviderService
          .sendServiceMessage(requisition.chatId, EServiceSocketMessageTypeDTO.CallStarted)
          .toPromise(),
      ) // TODO Rework service messages to not use chatId and use requisition Id instead
      .then(() => this.notifyBackendAboutCall(requisition.chatId))
      .then(() => this.notifyParticipantOnConnect(requisition.chatId))
      .then(() => this.notifyParticipantWasLeaveTheCall())
      .then(() => this.subscribeToDeclineMessage())
      .then(() => this._videoSessionState$.next(ECurrentVideoSessionState.Online))
      .catch((error) => {
        this.notifyError(error?.message, Infinity);
        this._videoSessionState$.next(ECurrentVideoSessionState.Error);
        this._closeDialog$.next();
        throw error;
      });
  }

  private async startCall(requisition: IRequisition): Promise<void> {
    this._sessions$.next([]);
    this._preventCurrentCallSubscriptions$.next();
    this._openDialog$.next();

    this._videoSessionState$.next(ECurrentVideoSessionState.Offline);

    return this.startSessionAsPromise()
      .then(() => this._videoSessionState$.next(ECurrentVideoSessionState.Connecting))
      .then(() =>
        this.chatApiProviderService
          .sendServiceMessage(requisition.chatId, EServiceSocketMessageTypeDTO.CallStarted)
          .toPromise(),
      )
      .then(() => this.notifyBackendAboutCall(requisition.chatId))
      .then(() => this.notifyParticipantOnConnect(requisition.chatId))
      .then(() => this.subscribeToDeclineMessage())
      .then(() => this._videoSessionState$.next(ECurrentVideoSessionState.Online))
      .catch((error) => {
        this.notifyError(error?.message);
        this._videoSessionState$.next(ECurrentVideoSessionState.Error);
        this._closeDialog$.next();
        throw error;
      });
  }

  private openVideoWindow(component: ComponentType<VideoCallsOverlayComponent | VideoconferenceComponent>) {
    const windowConfig: IModalWindowConfig = {
      title: TranslateService.localize('video-calls.conference-title'),
      disableChangeWindowMode: false,
      hideCloseButton: true,
      maxWindowModeSize: { height: 850, width: 1200 },
      minWindowModeSize: { height: 400, width: 425 },
    };

    const subscription = this.openDialog$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.overlayRef = this.modalOverlayService.openWindow(component, windowConfig, null, undefined, false);

      this.overlayRef.close$.subscribe(() => {
        subscription.unsubscribe();
        this.overlayRef = null;
      });
    });

    this.closeDialog$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.overlayRef?.close();
    });
  }

  private evaluateVideoCallQuality(): void {
    this.modalRef = this.modalOverlayService.openOverlay(RatingComponent);
    this.modalRef.instance.headerTitle = TranslateService.localize('modals.rating.title');

    this.modalRef.instance.submitRating$
      .pipe(takeUntil(race(this._destroy$.asObservable(), this.modalRef.instance.close$)))
      .subscribe((rating: ModalRating) => {
        rating.requisition_id = this._requisition$.value.id;

        this.modalRatingService.sendRating(rating).subscribe({
          complete: () => {
            this.modalRef.close();
          },
        });
      });

    this.modalRef.close$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._sessions$.next([]);
      this._requisition$.next(null);
    });
  }

  private initUpdatesLegacy(): void {
    this._sessions$.next([this.createSessionLegacy(this.myProfile.id, null)]);

    this.webRtcProviderService.participantAdded$
      .pipe(
        filter((participant) => participant.id !== this.myProfile.id),
        takeUntil(this._preventCurrentCallSubscriptions$),
      )
      .subscribe((participant) => {
        const currentSessions = this._sessions$.value;
        if (!currentSessions.find((session) => session.participantId === participant.id)) {
          this._sessions$.next(currentSessions.concat([this.createSessionLegacy(participant.id, null)]));
        }
      });

    this.webRtcProviderService.participantRemoved$
      .pipe(
        filter((participant) => participant.id !== this.myProfile.id),
        takeUntil(this._preventCurrentCallSubscriptions$),
      )
      .subscribe((participant) => {
        this._sessions$.next(this._sessions$.value.filter((s) => s.participantId !== participant.id));
      });

    this.webRtcProviderService.participantStreamUpdated$
      .pipe(takeUntil(this._preventCurrentCallSubscriptions$))
      .subscribe((participantStreamInfo) => {
        this._sessions$.next(
          this._sessions$.value.map((s) => {
            return s.participantId === participantStreamInfo.participantId
              ? this.createSessionLegacy(participantStreamInfo.participantId, participantStreamInfo.stream)
              : s;
          }),
        );
      });
  }

  private createSessionLegacy(participantId: TGuid, stream: MediaStream | null): IVideoCallSession {
    return {
      participantId,
      stream,
      profile: this.requisitionHelpersService.getParticipantById(this._requisition$.value, participantId),
      hasAudio: stream?.getAudioTracks()?.length > 0,
      hasVideo: stream?.getVideoTracks()?.length > 0,
    };
  }

  private notifyBackendAboutCall(chatId: TGuid): void {
    this.conferencesApiProviderService.notifyCalling(chatId).subscribe();
  }

  private notifyParticipantOnConnect(chatId: TGuid): void {
    merge<void>(
      this.socketMessagesProviderService.userConnected$.pipe(filter((event) => event.userId === this.participant.id)),
      this.socketMessagesProviderService.awake$.pipe(this.filterMessagesForCurrentCall()),
    )
      .pipe(
        switchMap(() =>
          this.chatApiProviderService.sendServiceMessage(chatId, EServiceSocketMessageTypeDTO.CallStarted),
        ),
        takeUntil(this._preventCurrentCallSubscriptions$),
      )
      .subscribe();
  }

  private notifyParticipantWasLeaveTheCall(): void {
    const DEFAULT_STATE = { byUserAction: false, byMissedStream: false };

    const state$ = new BehaviorSubject<typeof DEFAULT_STATE>(DEFAULT_STATE);

    const callEndServiceMessage$: Observable<IServiceMessageEvent> = this.socketMessagesProviderService.callEnded$.pipe(
      this.filterMessagesForCurrentCall(),
    );

    const missedSessionEvent$: Observable<void> = this._sessions$.pipe(
      pairwise(),
      filter(([prev, current]) => prev.length > 0 && current.length === 0),
      map(() => null),
    );

    callEndServiceMessage$.pipe(takeUntil(this._preventCurrentCallSubscriptions$)).subscribe(() => {
      state$.next({
        ...state$.value,
        byUserAction: true,
      });
    });

    missedSessionEvent$.pipe(takeUntil(this._preventCurrentCallSubscriptions$)).subscribe(() => {
      state$.next({
        ...state$.value,
        byMissedStream: true,
      });
    });

    const DEBOUNCE_BETWEEN_EVENTS_MS = 3000;

    state$
      .pipe(debounceTime(DEBOUNCE_BETWEEN_EVENTS_MS), takeUntil(this._preventCurrentCallSubscriptions$))
      .subscribe((values) => {
        if (values.byUserAction) {
          this.notificationsService.info({
            title: TranslateService.localize('video-calls.participant-ends-call-manually.title'),
            message: TranslateService.localize('video-calls.participant-ends-call-manually.message'),
            durationMs: Infinity,
          });
          state$.next(DEFAULT_STATE);
          return;
        }

        if (values.byMissedStream) {
          this.notificationsService.info({
            title: TranslateService.localize('video-calls.participant-stream-is-missed.title'),
            message: TranslateService.localize('video-calls.participant-stream-is-missed.message'),
            durationMs: Infinity,
          });
          state$.next(DEFAULT_STATE);
          return;
        }
      });
  }

  private subscribeToDeclineMessage(): void {
    this.socketMessagesProviderService.callDeclined$
      .pipe(this.filterMessagesForCurrentCall(), takeUntil(this._preventCurrentCallSubscriptions$))
      .subscribe(() => {
        if (this.overlayRef.instance instanceof VideoCallsOverlayComponent) {
          //This implementation should be changed or removed as soon as Voxeet is cancelled
          this.endCurrentCallLegacy();
        } else {
          this._videoSessionState$.next(ECurrentVideoSessionState.CallDeclined);
          this.overlayRef.instance.videoconference.left();
          this.endCurrentCall();
        }

        this.notifyError(
          TranslateService.localize('video-calls.declined.message'),
          10000,
          TranslateService.localize('video-calls.declined.title'),
        );
      });
  }

  private setControlsDefaultValuesLegacy(): void {
    this._isVideoEnabled$.next(false);
    this._isAudioEnabled$.next(true);
    this._isVideoToggleInProgress$.next(false);
    this._isAudioToggleInProgress$.next(false);
  }

  public checkDevicesWithRequestOverlay(deviceType: EMediaDevices): Promise<void> {
    let askOverlay: IModalComponentRef<AskForMediaDevicesOverlayComponent> | null = null;

    const deviceRequest$ = defer(() => this.mediaDevicesService.checkMediaDevice(deviceType));

    const timeoutMs = REQUEST_MEDIA_OVERLAY_DEBOUNCE_MS[deviceType];

    const debouncedAskOverlay$ = timer(timeoutMs).pipe(
      map(() => this.showAskForMediaOverlay(deviceType)),
      tap((modalRef) => (askOverlay = modalRef)),
      switchMap(() => askOverlay.close$),
      switchMap(() => throwError(new Error(`${deviceType} is disabled`))),
    );

    const req$ = race(deviceRequest$, debouncedAskOverlay$).pipe(
      first(),
      catchError(() => {
        askOverlay?.close();
        const overlay = this.showMediaRequiredMessageOverlay(deviceType);
        return overlay.close$.pipe(
          switchMap(() =>
            throwError(
              new Error(
                deviceType === 'audio'
                  ? TranslateService.localize('media-devices-overlay.require.microphone.title')
                  : TranslateService.localize('media-devices-overlay.require.camera.title'),
              ),
            ),
          ),
        );
      }),
      tap(() => {
        askOverlay?.close();
      }),
    );

    return req$.toPromise();
  }

  private showAskForMediaOverlay(deviceType: EMediaDevices): IModalComponentRef<AskForMediaDevicesOverlayComponent> {
    return this.modalOverlayService.openOverlay(
      AskForMediaDevicesOverlayComponent,
      {
        mediaDeviceToAsk: deviceType,
      },
      {
        closeByEsc: false,
        fullScreen: true,
      },
    );
  }

  private showMediaRequiredMessageOverlay(
    deviceType: EMediaDevices,
  ): IModalComponentRef<MediaDevicesRequiresMessageOverlayComponent> {
    return this.modalOverlayService.openOverlay(
      MediaDevicesRequiresMessageOverlayComponent,
      {
        mediaDeviceToAsk: deviceType,
      },
      {
        closeByEsc: false,
        fullScreen: true,
      },
    );
  }

  private filterMessagesForCurrentCall(): OperatorFunction<IServiceMessageEvent, IServiceMessageEvent> {
    return filter(
      (message) => message.chatId === this._requisition$.value.chatId && message.userId === this.participant.id,
    );
  }
}
