import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AGORA, ConferenceData, RTCTokenResponse, VOXEET } from '../definitions';
import { environment } from '@env';
import { VideoconferenceTransformer } from './videoconference-data.transformer';
import { HttpErrorResponse } from '@angular/common/http';
import {
  BadGatewayError,
  BadRequestError,
  ClientUnexpectedError,
  ConflictError,
  ForbiddenError,
  GatewayTimeoutError,
  InternalServerError,
  NotFoundError,
  NotImplementedError,
  ServerUnexpectedError,
  ServiceUnavailableError,
  TooManyRequestsError,
  UnauthorizedError,
} from 'src/app/core/http/errors';

@Injectable({
  providedIn: 'root',
})
export class VideoconferenceService {
  constructor(private apiService: ApiService) {}

  public getCredentials(): Observable<ConferenceData> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/VideoConference/rtc-token`).pipe(
      map(
        (response: RTCTokenResponse): ConferenceData => {
          switch (response.provider.toUpperCase()) {
            case AGORA:
              return VideoconferenceTransformer.agoraTransformer(response);
            case VOXEET:
              return VideoconferenceTransformer.voxeetTransformer(response);
            default:
              return null;
          }
        },
      ),
      catchError(this.handlerError),
    );
  }

  private handlerError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      return throwError(error);
    } else {
      if (error.status >= 400 && error.status <= 499) {
        switch (error.status) {
          case 400:
            return throwError(new BadRequestError());
          case 401:
            return throwError(new UnauthorizedError());
          case 403:
            return throwError(new ForbiddenError());
          case 404:
            return throwError(new NotFoundError());
          case 409:
            return throwError(new ConflictError());
          case 429:
            return throwError(new TooManyRequestsError());
          default:
            return throwError(new ClientUnexpectedError());
        }
      }

      if (error.status >= 500 && error.status <= 599) {
        switch (error.status) {
          case 500:
            return throwError(new InternalServerError());
          case 501:
            return throwError(new NotImplementedError());
          case 502:
            return throwError(new BadGatewayError());
          case 503:
            return throwError(new ServiceUnavailableError());
          case 504:
            return throwError(new GatewayTimeoutError());
          default:
            return throwError(new ServerUnexpectedError());
        }
      }
    }
  }
}
