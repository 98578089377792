import { Component, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-qr-code',
  templateUrl: './modal-qr-code.component.html',
  styleUrls: ['./modal-qr-code.component.scss'],
})
export class ModalQrCodeComponent implements OnInit {
  public close$ = new EventEmitter<void>();
  public submit$ = new EventEmitter<void>();
  public qrCode$: Observable<string>;
  public timer$: Observable<{ minutes: number; seconds: number }>;
  public isQrCodeExpired$: Observable<boolean>;
  public regenerate$ = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  regenerateQrCode() {
    this.regenerate$.emit();
  }

  close() {
    this.close$.emit();
  }
}
