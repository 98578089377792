<lib-modal-header (close)="close()">{{ "title-scan-qr-code" | translate }}</lib-modal-header>
<lib-modal-body>
  <div class="info-qr-code">{{ "title-scan-qr-code-camera" | translate }}</div>
  <div *ngIf="(isQrCodeExpired$ | async) === false; else expiredMessage" class="container-qr-code">
    <qrcode
      class="qrcodeURL"
      [qrdata]="qrCode$ | async"
      width="350"
      [errorCorrectionLevel]="'M'"
      style="margin-bottom: 2rem;"
    ></qrcode>
  </div>
  <ng-template #expiredMessage>
    <div class="qr-code-expired-message">
      <p>{{ "title-qr-code-expired-message" | translate }}</p>
      <button (click)="regenerateQrCode()">{{ "title-generate-new-qr-button" | translate }}</button>
    </div>
  </ng-template>
</lib-modal-body>
<lib-modal-footer>
  <div class="container-timer">
    <h3>{{ "title-qr-code-expiration-time" | translate }}</h3>
    <h2 class="minutes-timer">{{ (timer$ | async)?.minutes }}:{{ (timer$ | async)?.seconds | number: "2.0" }}</h2>
  </div>
</lib-modal-footer>
