import { TDateISOString, TGuid } from '@core/helpers';
import { IQuestionnaire, IQuestionnaireQuestion } from './questionnaire.view-model';
import { IRequisitionAnswerDTO, IRequisitionAnswerValueDTO, IRequisitionDTO } from './dto/requisition.dto';
import { ERequisitionStatusDTO } from './consts/requisition-consts';
import { EQuestionnaireAnswersTypeDTO } from './consts/questionnaire';
import { IPatient, PatientViewModelFactory } from './patient.view-model';
import { DoctorViewModelFactory, IDoctor } from './doctor.view-model';
import { IReceptionist, ReceptionistViewModelFactory } from './receptionist.view-model';
import { IRequisitionStage, RequisitionStageViewModelFactory } from './requisition-stage.view-model';
import { ITaskShort, TaskShortViewModelFactory } from './task-short.view-model';

export interface IRequisitionStatus {
  date_create: TDateISOString;
  status: string;
}

export interface IRequisitionStatusDTO {
  date_create: TDateISOString;
  status: string;
}
interface IRequisitionAnswer {
  id: TGuid;
  title: string;
}

interface IRequisitionQuestion {
  id: TGuid;
  title: string;
  answers: IRequisitionAnswer[];
}

export interface IRequisition {
  id: TGuid;
  questionnaireId: TGuid;
  patientProfileId: TGuid;
  chatId: TGuid | null;
  status: ERequisitionStatusDTO;
  patient: IPatient | null;
  receptionist: IReceptionist | null;
  doctors: IDoctor[];
  activeStage: IRequisitionStage | null;
  tasks: ITaskShort[];
  externalAuthCode: string;
}

export interface IRequisitionWithQuestionsAnswers extends IRequisition {
  questions: IRequisitionQuestion[];
}

export class RequisitionStatusViewModelFactory {
  static createFromDTO(dto: IRequisitionStatusDTO): IRequisitionStatus {
    return {
      date_create: dto.date_create,
      status: dto.status,
    };
  }
  static createDTO(model: IRequisitionStatus): IRequisitionStatusDTO {
    return {
      date_create: model.date_create,
      status: model.status,
    };
  }
}

export class RequisitionViewModelFactory {
  static createGetViewModel(dto: IRequisitionDTO): IRequisition {
    return {
      id: dto.id,
      chatId: dto.chat_id,
      patientProfileId: dto.patient_profile_id,
      questionnaireId: dto.questionnaire_id,
      status: dto.status,
      patient: dto.patient ? PatientViewModelFactory.createFromDTO(dto.patient) : null,
      receptionist: dto.receptionist ? ReceptionistViewModelFactory.createFromDTO(dto.receptionist) : null,
      doctors: dto.doctors.map((doc) => DoctorViewModelFactory.createFromDTO(doc)),
      activeStage: dto.active_stage ? RequisitionStageViewModelFactory.createGetViewModel(dto.active_stage) : null,
      tasks: dto.tasks.map((taskDto) => TaskShortViewModelFactory.createGetViewModel(taskDto)),
      externalAuthCode: dto.externalAuthCode,
    };
  }

  static createGetViewModelWithAnswers(
    dto: IRequisitionDTO,
    questionnaire: IQuestionnaire,
  ): IRequisitionWithQuestionsAnswers {
    return {
      ...this.createGetViewModel(dto),
      questions: dto.questionnaire_answers.answers
        .map((question) => this.createQuestion(question, questionnaire))
        .filter((question) => Boolean(question)),
    };
  }

  private static createQuestion(dto: IRequisitionAnswerDTO, questionnaire: IQuestionnaire): IRequisitionQuestion {
    const question = questionnaire.questionsMap[dto.question_id];

    if (!question) {
      console.error(new Error(`No question with id ${dto.question_id} found`));
      return null;
    }

    const answers = dto.allowed_values.map((item) => this.getRequisitionAnswerPreview(question, item));

    return {
      id: dto.question_id,
      title: question.title,
      answers: answers.filter((a) => Boolean(a)),
    };
  }

  private static getRequisitionAnswerPreview(
    question: IQuestionnaireQuestion,
    answerValueDTO: IRequisitionAnswerValueDTO,
  ): IRequisitionAnswer | null {
    if (question.answer.type === EQuestionnaireAnswersTypeDTO.Text) {
      // For text question we should use value input by user (from answerValueDTO)
      return {
        id: answerValueDTO.id,
        title: answerValueDTO.value,
      };
    } else {
      /**
       * For non text questions we have only answer option Id as answer value
       * so we should use answer option title as string to preview answer in UI
       */

      const answerOption = question.answer.values.find((v) => v.id === answerValueDTO.id);

      if (!answerOption) {
        console.error(new Error(`No answer with id ${answerValueDTO.id} found`));
        return null;
      }

      return {
        id: answerValueDTO.id,
        title: answerOption.title,
      };
    }
  }
}
